import React from "react";
import CountUp from "react-countup";

// image
import Ab1 from "../../images/about.jpg";
import Abd1 from "../../images/doctors/1.jpg";
import Abd2 from "../../images/doctors/2.jpg";
import Abd3 from "../../images/doctors/3.jpg";
import Abd4 from "../../images/doctors/4.jpg";
import sine from "../../images/signeture.png";
import ourMissionPhoto from "../../images/mc-dencia-photos/our-mission-photo.png";

const about = (props) => {
  return (
    <section className={"" + props.hclass}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-12">
            <div className="about_left">
              <div className="image">
                <img src={ourMissionPhoto} alt="" />
                <span className="round-on"></span>
                <span className="round-two"></span>
                <div className="award">
                  <div className="icon">
                    <i className="flaticon-cup"></i>
                  </div>
                  <div className="text">
                    <h2>
                      <CountUp end={22} enableScrollSpy />+
                    </h2>
                    <p>Години опит</p>
                  </div>
                </div>
                {/* <div className="doctors">
                                        <ul>
                                        <li><img src={Abd1} alt="" /></li>
                                        <li><img src={Abd2} alt="" /></li>
                                        <li><img src={Abd3} alt="" /></li>
                                        <li><img src={Abd4} alt="" /></li>
                                            <li><span>95+</span></li>
                                        </ul>
                                        <h4>Available Doctors</h4>
                                    </div> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="content">
              {/* <h2>За Нас</h2> */}
              <h3>Нашата мисия</h3>
              <p>
                Нашата мисия е да предоставяме висококачествена медицинска
                грижа, която да подпомага здравето и благополучието на нашите
                пациенти. Ние се стремим да използваме най-новите технологии и
                методи за лечение, за да осигурим най-доброто възможно
                обслужване. Нашата цел е да създадем комфортна и доверителна
                среда, където всеки пациент се чувства специален и обгрижен.
              </p>
              <p>
                Работим усилено, за да предоставим персонализирана грижа,
                съобразена с индивидуалните нужди на всеки пациент. Вярваме, че
                здравето е основата на щастливия живот и сме тук, за да ви
                подкрепим във всяка стъпка към по-здравословно бъдеще.
              </p>

              {/* <div className="ceo">
                                <div>
                                    <h4>Savannah Nguyen</h4>
                                    <span>CEO & Founder of Madically</span>
                                </div>
                                <div>
                                    <img src={sine} alt="" />
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default about;
