import React from "react";
import { Link } from "react-router-dom";
import Teams from "../../api/team";
import SectionTitle from "../SectionTitle/SectionTitle";
import borderShape from "../../images/team/border-shape.svg";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const TeamSection = (props) => {
  const {
    hclass,
    sliceStart = 0,
    sliceEnd = 3,
    showSectionTitle = true,
  } = props;
  return (
    <section className={hclass} style={{paddingBottom:"0"}}>
      <div className="container">
        {showSectionTitle && (
          <div className="row justify-content-center">
            <div className="col-lg-9 col-12">
              <SectionTitle
                title={"Нашите партньори"}
                subtitle={"Нашите партньори"}
              />
            </div>
          </div>
        )}
        <div
          className="row"
          style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
        >
          {Teams.slice(sliceStart, sliceEnd).map((team, titem) => (
            <div className="col-lg-4 col-md-6 col-12" key={titem}>
              <div className="team_card">
                <div className="image">
                  <img src={team.timg} alt="img"  onClick={(e) =>{e.preventDefault(); openInNewTab(team.url); console.log(10)}}/>
                  {/* <div className="border-shape"> */}
                  {/* <img src={borderShape} alt="" /> */}
                  {/* </div> */}
                </div>
                <div className="content">
                  <h3>
                  {team.title}
                  </h3>
                  <span>{team.subtitle}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default TeamSection;
