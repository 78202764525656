// import "faMale" from "../images/GP/"faMale"doctor.PNG";
// import "faFemale" from "../images/GP/"faFemale"doctor.PNG";

const Doctors = [
  {
    id: 1,
    name: "Валентин Лилов",
    phone: "+359889616137",
    otherSpecialist: [
      {
        id: 201,
        helperName: "Иванка Младенова",
        otherSpec: "Вътрешни болести",
      },
    ],
    helper: [
      {
        id: 2
      },
    ],
    otherSpec: null,
    icon: "faMale",
    hoursPerDay: [
      "08:30 - 13:00",
      "08:30 - 13:00",
      "08:30 - 13:00",
      "08:30 - 13:00",
      "08:30 - 13:00",
    ],
    workingType: [
      "Амбулаторни прегледи",
      "Домашни прегледи",
      "Промотивна и профилактична дейност",
      "Дейност по детско здравеопазване",
      "Дейност по майчинско здравеопазване",
    ],
  },
  {
    id: 2,
    name: "Веселка Велкова",
    otherSpecialist: [],
    phone: "+359888213285",
    helper: [
      {
        id: 1
      },
    ],
    otherSpec: "белодробни болести",
    icon: "faFemale",
    hoursPerDay: [
      "13:00 - 18:00",
      "13:00 - 18:00",
      "13:00 - 18:00",
      "08:30 - 13:00",
      "08:30 - 13:00",
    ],
    workingType: [
      "Амбулаторни прегледи",
      "Домашни прегледи",
      "Промотивна и профилактична дейност",
      "Дейност по детско здравеопазване",
      "Дейност по майчинско здравеопазване",
    ],
  },
  {
    id: 3,
    name: "Цветан Първанов",
    otherSpecialist: [],
    phone: "+359877185393",
    helper: [
      {
        id: 4
      },
    ],
    otherSpec: "вътрешни болести",

    icon: "faMale",
    hoursPerDay: [
      "08:00 - 13:00",
      " ",
      "08:00 - 14:00",
      "12:00 - 18:00",
      "08:00 - 13:00",
    ],
    workingType: [
      "Амбулаторни прегледи",
      "Домашни прегледи",
      "Промотивна и профилактична дейност",
      "Дейност по детско здравеопазване",
      "Дейност по майчинско здравеопазване",
    ],
    more: ["Извършва прегледи в с. Горно Церовене"],
  },
  {
    id: 4,
    name: "Ирена Илиева",
    otherSpecialist: [],
    phone: "+359887236000",
    helper: [
      {
        id: 3
      },
    ],
    otherSpec: null,
    icon: "faFemale",
    hoursPerDay: [
      "08:30 - 13:30",
      "08:30 - 13:30",
      "08:30 - 13:30",
      "08:30 - 13:30",
      "08:30 - 13:00",
    ],
    workingType: [
      "Амбулаторни прегледи",
      "Домашни прегледи",
      "Промотивна и профилактична дейност",
      "Дейност по детско здравеопазване",
      "Дейност по майчинско здравеопазване",
    ],
  },
  {
    id: 5,
    name: "Стилиян Младенов",
    otherSpecialist: [],
    helper: [
      {
        id: 6
      },
    ],
    phone: "+359888899357",
    otherSpec: null,

    photo: "faMale",
    hoursPerDay: [" ", "14:00 - 17:00", " ", "14:00 - 17:00", " "],
    workingType: [
      "Амбулаторни прегледи",
      "Домашни прегледи",
      "Промотивна и профилактична дейност",
      "Дейност по детско здравеопазване",
      "Дейност по майчинско здравеопазване",
    ],
    more: ["Извършва прегледи в с. Долно Церовене и с. Дългоделци"],
  },
  {
    id: 6,
    name: "Георги Цветанов",
    mf: "м.ф. Искрен Корчев",
    otherSpecialist: [],
    phone: "+359885320234",
    helper: [
      {
        helperName: "Марсинела Любомирова",
        helperPhone: "",
        helperPhoto: "faFemale",
      },
    ],
    otherSpec: null,
    icon: "faMale",
    hoursPerDay: [
      "08:00 - 10:00 и 13:30 - 16:00",
      "08:00 - 10:00 и 13:30 - 16:00",
      "08:00 - 10:00 и 13:30 - 16:00",
      "08:00 - 10:00 и 13:30 - 16:00",
      "08:00 - 10:00 и 14:30 - 16:00",
    ],
    workingType: [
      "Амбулаторни прегледи",
      "Домашни прегледи",
      "Промотивна и профилактична дейност",
      "Дейност по детско здравеопазване",
      "Дейност по майчинско здравеопазване",
    ],
    more: ["Извършва прегледи в гр. Бойчиновци, с. Септемврийци и с. Мадан"],
  },
];

export default Doctors;
