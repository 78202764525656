const SerivicesLkk = [
    {
      id: 20,
      name: "Обща ЛКК",
      icon: "faFileWord",
      info: [],
    },
    {
      id: 21,
      name: "Специализирана ЛКК Акушерство и Гинекология",
      icon: "faFileWord",
      info: [],
    },
    /*
      { 
          id: 22,
          name: 'Специализирана ЛКК ХИРУРГИЯ',
          hasNZOK: false,
          preBooking: false,
          picture: NoteAndPen,
          info: [],
          hasButton: false,
          specialists: []
      },
    */
    {
      id: 23,
      name: "Издаване на медицински",
      icon: "faFileWord",
      info: [
        "Шофьор, Работа, Брак",
        "Работа в специализирана институция,",
        "Оръжие, Студент, Осиновяване",
      ],
    },
    {
      id: 24,
      name: "Медицински удостоверения",
      icon: "faFileWord",
      info: [
        "Протоколи за ТЕЛК, Бюро по труда, Cоциални грижи",
        "Психиатрично удостоверение, Етапна епикриза",
        "Медицинска характеристика за постъпване в социален дом",
      ],
    },
  ];
  
  export default SerivicesLkk;