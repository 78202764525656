import React, { useState } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "../MobileMenu/MobileMenu";
import { totalPrice } from "../../utils";
import { connect } from "react-redux";
import { removeFromCart } from "../../store/actions/action";
import "../../sass/header.css";

const Header = (props) => {
  const [menuActive, setMenuState] = useState(false);
  const [cartActive, setcartState] = useState(false);

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const { carts } = props;

  return (
    <header id="header">
      <div className={"" + props.hclass}>
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <div
              className="row align-items-center"
              style={{ justifyContent: "space-between" }}
            >
              <div className="col-lg-3 col-md-6 col-6 logo-container">
                <div className="navbar-header">
                  <Link
                    onClick={ClickHandler}
                    className="navbar-brand"
                    to="/home"
                  >
                    <img src={props.Logo} alt="logo" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-9 col-md-1 col-1">
                <div
                  id="navbar"
                  className="collapse navbar-collapse navigation-holder"
                >
                  <button className="menu-close">
                    <i className="ti-close"></i>
                  </button>
                  <ul
                    className="nav navbar-nav mb-2 mb-lg-0"
                    style={{ fontWeight: "bolder" }}
                  >
                    <li>
                      <Link onClick={ClickHandler} to="/home">
                        Начало
                      </Link>
                    </li>

                    {/* <li className="menu-item-has-children">
                      <Link onClick={ClickHandler} to="#">
                        Начало
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link onClick={ClickHandler} to="/home">
                            Home style 1
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/home-2">
                            Home style 2
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/home-3">
                            Home style 3
                          </Link>
                        </li>
                      </ul>
                    </li> */}
                    <li className="menu-item-has-children">
                      <Link to="/#">Лаборатория и резултати</Link>
                      <ul className="sub-menu">
                        <li>
                          <Link onClick={ClickHandler} to="/laboratory">
                            Лаборатория
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/results">
                            Проверка на рабораторни резултати
                          </Link>
                        </li>
                      </ul>
                    </li>
                    
                    <li className="menu-item-has-children">
                      <Link onClick={ClickHandler} to="/specialities">
                        Специалности
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link onClick={ClickHandler} to="/specialities">
                            Специалности
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/specialities-lkk">
                            Лекарски Консултативни Комисии
                          </Link>
                        </li>
                      </ul>
                    </li>
                    {/* <li>
                      <Link onClick={ClickHandler} to="/laboratory">
                        Лаборатория
                      </Link>
                    </li> */}
                    <li>
                      <Link onClick={ClickHandler} to="/doctors">
                        Лични лекари
                      </Link>
                    </li>
                    <li className="menu-item-has-children">
                      <Link to="/#">Услуги</Link>
                      <ul className="sub-menu">
                        <li>
                          <Link
                            onClick={ClickHandler}
                            to="/project-single/clinical-psychology"
                          >
                            Клинична психология
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={ClickHandler}
                            to="/project-single/medical-cosmetics"
                          >
                            Медицинска козметика
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <Link onClick={ClickHandler} to="/contact">
                        Контакти
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-3 col-2 d-lg-none dl-block"
                style={{ display: "flex", justifyContent: "right" }}
              >
                <MobileMenu />
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};
const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
  };
};

export default connect(mapStateToProps, { removeFromCart })(Header);
