import React, { Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Projects from "../../api/projects";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Logo from "../../images/mc-dencia-photos/BothLogos1.png";
import CtafromSection from "../../components/CtafromSection/CtafromSection";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

function formatPhoneNumber(phoneNumber) {
  let digits = phoneNumber.replace(/\D/g, "");

  if (digits.startsWith("359") && digits.length === 12) {
    let formattedNumber = `+359-${digits.slice(3, 5)}-${digits.slice(
      5,
      8
    )}-${digits.slice(8, 11)}`;
    return formattedNumber;
  } else {
    return phoneNumber;
  }
}

const ProjectSingle = (props) => {
  const { slug } = useParams();

  const ProjectSingle = Projects.find((item) => item.slug === slug);

  return (
    <Fragment>
      <Navbar Logo={Logo} hclass={"wpo-site-header wpo-site-header-s2"} />

      <PageTitle
        pageTitle={ProjectSingle.title}
        pagesub={ProjectSingle.title}
      />
      <section className="project_single section-padding">
        <div className="container">
          <img src={ProjectSingle.pSimg} alt="" />
          <div className="row">
            <div className="col-lg-7 col-12 strategies">
              <h3>{ProjectSingle.subtitle}</h3>
              <ul>
                {ProjectSingle.topics.map((e, ind) => (
                  <li key={`topic_` + ind}>{e}</li>
                ))}
              </ul>
            </div>
            <div className="col-lg-5 col-12">
              <table>
                <tbody>
                  {ProjectSingle.specialist && (
                    <tr>
                      <th>Специалист:</th>
                      <td>{ProjectSingle.specialist}</td>
                    </tr>
                  )}
                  {ProjectSingle.phone && (
                    <tr>
                      <th>Телефон:</th>
                      <td><a className="contact" href={`tel:${ProjectSingle.phone}`}>{formatPhoneNumber(ProjectSingle.phone)}</a></td>
                    </tr>
                  )}
                  {ProjectSingle.email && (
                    <tr>
                      <th>Email:</th>
                      <td><a className="contact" href={`mailto:${ProjectSingle.email}`}>{ProjectSingle.email}</a></td>
                    </tr>
                  )}
                  {ProjectSingle.workTime && (
                    <tr>
                      <th>Работно време:</th>
                      <td>{ProjectSingle.workTime}</td>
                    </tr>
                  )}
                  <tr>
                    <th>Share:</th>
                    <td>
                      <ul>
                        <li>
                          <Link to="#">
                            <i className="flaticon-facebook-app-symbol"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="flaticon-twitter"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="flaticon-linkedin"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="flaticon-instagram"></i>
                          </Link>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="quote">
            <h4>{ProjectSingle.quote.text}</h4>
            <p>{ProjectSingle.quote.author}</p>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12 strategies s2">
              <h3>Нашите стратегии</h3>
              <p>{ProjectSingle.strategy.desctiption}</p>
              <ul>
                {ProjectSingle.strategy.bennefits.map((e, ind) => (
                  <li key={`strategy_` + ind}>{e}</li>
                ))}
              </ul>
            </div>
            <div className="col-lg-6 col-12 strategies">
              <h3>Нашият подход</h3>
              <p>{ProjectSingle.approach.desctiption}</p>
              <ul>
                {ProjectSingle.approach.bennefits.map((e, ind) => (
                  <li key={`approach_` + ind}>{e}</li>
                ))}
              </ul>
            </div>
          </div>
          {/* <div className="related_project">
            <h5>Related Portfolio</h5>
            <div className="row">
              {Projects.slice(0, 3).map((project, pitem) => (
                <div className="col-lg-4 col-md-6 col-12" key={pitem}>
                  <div className="project_card">
                    <img src={project.pimg1} alt="" />
                    <div className="text">
                      <h3>
                        <Link
                          onClick={ClickHandler}
                          to={`/project-single/${project.slug}`}
                        >
                          {project.title}
                        </Link>
                      </h3>
                      <span>{project.subtitle}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div> */}
        </div>
      </section>
      <CtafromSection hclass={'ctafrom_section'} />
      <Footer hclass={'wpo-site-footer'} />
      <Scrollbar />
    </Fragment>
  );
};
export default ProjectSingle;
