import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle/SectionTitle";
import Doctors from "../../api/Doctors";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMale, faFemale } from "@fortawesome/free-solid-svg-icons";

const getIcon = (name) => {
  switch (name) {
    case "faFemale":
      return faFemale;
    default:
      return faMale;
  }
};

const DoctorsSection = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const {
    hclass,
    sliceStart = 0,
    sliceEnd = 3,
    showSectionTitle = true,
    AllServices = true,
  } = props;

  return (
    <section className={hclass}>
      <div className="container">
        {showSectionTitle && (
          <div className="row justify-content-center">
            <div className="col-lg-9 col-12">
              <SectionTitle
                title={"Departmental Services"}
                subtitle={"Our Medical Services"}
              />
            </div>
          </div>
        )}
        <div className="row">
          {Doctors.map((servic, sky) => (
            <div className="col-lg-4 col-md-6 col-12" key={sky}>
              <div className="service_card">
                <div className="icon">
                  <i>
                    <FontAwesomeIcon
                      className="single-speciality-icon"
                      icon={getIcon(servic.icon)}
                    />
                  </i>
                </div>
                <div className="content">
                  <h4>Д-р {servic.name}</h4>
                  <p>
                    Специалист обща медицина{" "}
                    {servic.otherSpec && `и ${servic.otherSpec}`}
                  </p>
                  <Link
                    onClick={ClickHandler}
                    to={`/doctor-single/${servic.id}`}
                  >
                    <i className="flaticon-right-arrow"></i>
                  </Link>
                </div>
              </div>
            </div>
          ))}
          {AllServices && (
            <div className="col-12">
              <div className="btn">
                <Link
                  onClick={ClickHandler}
                  to={"/services"}
                  className="theme-btn"
                >
                  See All Services
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default DoctorsSection;
