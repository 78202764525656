import React, { Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import Guests from "../../api/Guests";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import ContactForm from "../ServiceSinglePage/ServiceFrom ";
// import logo from "../../images/logo-2.svg";
import Arrow from "../../images/team-single/arrow.svg";
import Logo from "../../images/mc-dencia-photos/BothLogos1.png";
import CtafromSection from "../../components/CtafromSection/CtafromSection";

const progressData = [
  // { label: 'Successful Surgery', value: 85 },
  { label: "Доволни пациенти", value: 95 },
  { label: "Превенция от усложнения", value: 98 },
  { label: "Рейтинг от клиенти", value: 85 },
];

function formatDate(d) {
  const date = new Date(d);
  const months = [
    "януари",
    "февруари",
    "март",
    "април",
    "май",
    "юни",
    "юли",
    "август",
    "септември",
    "октомври",
    "ноември",
    "декември",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}


const TeamSinglePage = (props) => {
  const { slug } = useParams();
  const GuestSingle = Guests.find((item) => item.slug === slug);

  return (
    <Fragment>
      <Navbar Logo={Logo} hclass={"wpo-site-header wpo-site-header-s2"} />
      <PageTitle pageTitle={GuestSingle?.title} pagesub={GuestSingle?.title} />
      <section className="team_single_page section-padding">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-6 col-12">
              <div className="doctor_profile">
                <img
                  src={GuestSingle?.Sime}
                  alt=""
                  style={{ marginBottom: "100px" }}
                />
                <div className="content">
                  <h4>
                    <b>{GuestSingle?.title}</b>
                  </h4>
                  <span>{GuestSingle?.spec}</span>
                  {/* <ul>
                    <li>
                      <Link to="#">
                        <i className="flaticon-facebook-app-symbol"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="flaticon-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="flaticon-linkedin"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="flaticon-instagram"></i>
                      </Link>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6  col-12">
              <div className="doctor_info">
                <h3>Информация</h3>
                <table>
                  <tbody>
                    <tr>
                      <th>Специалност:</th>
                      <td>{GuestSingle?.spec}</td>
                    </tr>
                    <tr>
                      <th>Локация на прегледа:</th>
                      <td>Медицински център "Деница ООД"</td>
                    </tr>
                    <tr>
                      <th>Гост от:</th>
                      <td>{GuestSingle.baseLocation}</td>
                    </tr>
                    <tr>
                      <th>Дата:</th>
                      <td>{formatDate(GuestSingle.date)}</td>
                    </tr>
                    <tr>
                      <th>Час:</th>
                      <td>{GuestSingle?.hour}</td>
                    </tr>
                    <tr>
                      <th>Телефон за записване:</th>
                      <td>{GuestSingle?.contacts}</td>
                    </tr>
                    {/* <tr>
                                            <th>Email:</th>
                                            <td>youremail@gmail.com</td>
                                        </tr> */}
                  </tbody>
                </table>
              </div>
              {/* <div className="doctor_info s2">
                                <h3>Education</h3>
                                <ul>
                                    <li><img src={Arrow} alt="" />MBBS University of California
                                    </li>
                                    <li><img src={Arrow} alt="" />Medify Institute of Medicine
                                        Juzment School of Management,Cambridge</li>
                                    <li><img src={Arrow} alt="" />The Syntify High School Of New
                                        York</li>
                                    <li><img src={Arrow} alt="" />Education &Medical Admissions
                                    </li>
                                </ul>
                            </div> */}
            </div>
          </div>
          <div className="experience_wrap">
            <div className="top_content">
              <h3>Допълнителна информация</h3>
              <p>{GuestSingle?.text}</p>

              {GuestSingle?.additional ? (
                <p>{GuestSingle.additional}</p>
              ) : (
                <></>
              )}
            </div>
            <div className="skill_wrap">
              <div className="skill">
                <h3>Персонални умения</h3>
                {progressData?.map((item, index) => (
                  <div className="progress_item" key={index}>
                    <span>{item.label}</span>
                    <div className="progres">
                      <div
                        className="progress-value"
                        style={{ width: `${item.value}%` }}
                      >
                        <span>{item?.value}%</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className="achievements">
                <h3>Achievements</h3>
                <ul>
                  <li>
                    <span>2018 - 2019 : </span>William Allan Award
                  </li>
                  <li>
                    <span>2020 - 2021 : </span>Top Medical Resigning Star Award{" "}
                  </li>
                  <li>
                    <span>2022 - 2023 : </span>Mother Philips Award
                  </li>
                  <li>
                    <span>2024 - 2025 : </span>Institute of Top Medication Award
                  </li>
                  <li>
                    <span>2017 - 2018 : </span>Harvard University Award
                  </li>
                  <li>
                    <span>2016 - 2017 : </span>Best Doctor Award
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
        {/* <div className="AppointmentFrom">
                    <div className="container">
                        <div className="cta_form_s2">
                            <div className="title s2">
                                <h3>Make An Appointment</h3>
                                <p>Get in touch with us to see how we can help you with your Problems.</p>
                            </div>
                            <ContactForm />
                        </div>
                    </div>
                </div> */}
      </section>
      <CtafromSection hclass={'ctafrom_section'} />
            <Footer hclass={'wpo-site-footer'} />
      <Scrollbar />
    </Fragment>
  );
};
export default TeamSinglePage;
