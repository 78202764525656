import BulgariaZdrave from "./../images/partners/BulgariaZdrave1.png";

import Bulstard from "./../images/partners/Bulstrad1.jpg";
import DZI from "./../images/partners/DZI1.jpg";
import Euroins from "./../images/partners/Euroins1.png";
import Fihealth from "./../images/partners/fihealth1.png";
import GeneraliOne from "./../images/partners/Generali1.jpg";
import Nzok from "./../images/partners/NZOK1.png";
import OZFDoverie from "./../images/partners/OZF-Doverie1.jpg";
import Suglasie from "./../images/partners/Suglasie1.jpg";
import Uniqua from "./../images/partners/Uniqua1.jpg";
import Alianz from "./../images/partners/Alianz.png";
import Axiom from "./../images/partners/axiom.jpeg";

const Partners = [
  {
    id: 1,
    name: "НЗОК",
    image: Nzok,
    url: "https://www.nhif.bg/",
  },
  {
    id: 2,
    name: "ЗД България Здраве",
    image: BulgariaZdrave,
    url: "https://www.bulgariainsurance.bg/",
  },
  {
    id: 3,
    name: "ЗД Булстарад Живот",
    image: Bulstard,
    url: "https://www.bulstradlife.bg/",
  },
  {
    id: 4,
    name: "Дзи Животозастраховане",
    image: DZI,
    url: "https://www.dzi.bg/",
  },
  {
    id: 5,
    name: "ЗД Евроинс АД",
    image: Euroins,
    url: "https://www.euroins.bg/",
  },
  {
    id: 6,
    name: "Фи Хелт",
    image: Fihealth,
    url: "https://www.fihealth.bg/",
  },
  {
    id: 7,
    name: "Дженерали Застраховане",
    image: GeneraliOne,
    url: "https://www.generalicee.com/",
  },
  {
    id: 8,
    name: "ОЗОФ Доверие",
    image: OZFDoverie,
    url: "https://www.ozof-doverie.bg/",
  },
  {
    id: 9,
    name: "ЗД Съгласие АД",
    image: Suglasie,
    url: "https://zd.saglasie.bg/",
  },
  {
    id: 10,
    name: "Уника",
    image: Uniqua,
    url: "https://www.uniqa.bg/",
  },
  {
    id: 11,
    name: "Алианц",
    image: Alianz,
    url: "https://www.allianz.bg/bg_BG/individuals.html",
  },
  {
    id: 12,
    name: "Аксиом АД",
    image: Axiom,
    url: "https://axiom-jsc.com/",
  },
];

export default Partners;
