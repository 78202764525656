import React, { Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import Services from "../../api/Services";
import ServiceSidebar from "./sidebar";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Logo from "../../images/mc-dencia-photos/BothLogos1.png";
import CtafromSection from "../../components/CtafromSection/CtafromSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBaby,
  faLungs,
  faFileWord,
  faEye,
  faStethoscope,
  faCrutch,
  faPlus,
  faHeart,
  faBrain,
  faPersonDotsFromLine,
  faXRay,
  faLungsVirus,
  faToilet,
  faPumpMedical,
  faHeadSideMask,
  faBedPulse,
  faDna,
  faPills,
  faHeadSideVirus,
} from "@fortawesome/free-solid-svg-icons";

const getIcon = (name) => {
  switch (name) {
    case "faBedPulse":
      return faBedPulse;
    case "faDna":
      return faDna;
    case "faPills":
      return faPills;
    case "faHeadSideVirus":
      return faHeadSideVirus;
    case "faBaby":
      return faBaby;
    case "faLungs":
      return faLungs;
    case "faFileWord":
      return faFileWord;
    case "faEye":
      return faEye;
    case "faStethoscope":
      return faStethoscope;
    case "faCrutch":
      return faCrutch;
    case "faHeart":
      return faHeart;
    case "faBrain":
      return faBrain;
    case "faPersonDotsFromLine":
      return faPersonDotsFromLine;
    case "faXRay":
      return faXRay;
    case "faLungsVirus":
      return faLungsVirus;
    case "faToilet":
      return faToilet;
    case "faPumpMedical":
      return faPumpMedical;
    case "faHeadSideMask":
      return faHeadSideMask;
    default:
      return faPlus;
  }
};

const ServiceSinglePage = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const { slug } = useParams();
  const serviceDetails = Services.find((item) => item.slug === slug);

  return (
    <Fragment>
      <Navbar Logo={Logo} hclass={"wpo-site-header wpo-site-header-s2"} />
      <PageTitle pageTitle={serviceDetails.title} pagesub={"Специалности"} />
      <section className="service_single section-padding">
        <div className="container">
          <div className="row g-0">
            <div className="col-lg-8 col-12 service_content">
              <div>
                <p>{serviceDetails.description}</p>
              </div>
              {serviceDetails.capabilities.length > 0 && (
                <div className="p-div-4">
                  <h3>Нашите услуги</h3>
                  <ul>
                    {serviceDetails.capabilities.map((c) => (
                      <li key={`${c}`}>{c}</li>
                    ))}
                  </ul>
                </div>
              )}
              {serviceDetails.hasNZOK && (
                <div className="p-div-4">
                  <h3>Нзок</h3>
                  <ul>
                    <li>
                      {" "}
                      Спеиалността има договор с "Национална здравноосигурителна
                      каса"
                    </li>
                  </ul>
                </div>
              )}
              <div className="p-div-4">
                <h3>Нашият екип</h3>
                {serviceDetails?.specialists?.length && (
                  <ul>
                    {serviceDetails.specialists.map((s, i) => (
                      <li key={"sg" + i}>
                        {s}
                        {serviceDetails?.schedule?.[i] && (
                          <>
                            {" "}
                            <br></br>
                            <br></br>
                            <>
                              <table
                                className="table_web"
                                style={{
                                  width: "100%",
                                  borderCollapse: "collapse",
                                  // marginBottom: "20px",
                                  fontSize: "19px",
                                  marginLeft: "-15px",
                                  border:
                                    "1px solid rgba(76, 101, 147, 0.7411764706)",
                                }}
                              >
                                <thead>
                                  <tr>
                                    {[
                                      "Понеделник",
                                      "Вторник",
                                      "Сряда",
                                      "Четвъртък",
                                      "Петък",
                                    ].map((day, index) => (
                                      <th
                                        key={"day_" + index + "" + i}
                                        style={{
                                          border:
                                            "1px solid rgba(76, 101, 147, 0.7411764706)",
                                          padding: "10px",
                                          backgroundColor:
                                            "rgba(76, 101, 147, 0.7411764706)",
                                          color: "#fff",
                                        }}
                                      >
                                        {day}
                                      </th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    {serviceDetails?.schedule[i]?.times.map(
                                      (t, ind) => (
                                        <td
                                          style={{
                                            border:
                                              "1px solid rgba(76, 101, 147, 0.7411764706)",
                                            padding: "10px",
                                          }}
                                          key={`tim_` + i + "" + ind}
                                        >
                                          {t ? `${t} ч.` : ""}
                                        </td>
                                      )
                                    )}
                                  </tr>
                                </tbody>
                              </table>
                              <table
                                className="table_mobile"
                                style={{
                                  width: "100%",
                                  borderCollapse: "collapse",
                                  marginBottom: "20px",
                                  fontSize: "19px",
                                  border:
                                    "1px solid rgba(76, 101, 147, 0.7411764706)",
                                }}
                              >
                                <tbody>
                                  {[
                                    "Понеделник",
                                    "Вторник",
                                    "Сряда",
                                    "Четвъртък",
                                    "Петък",
                                  ].map((day, index) => (
                                    <tr key={index}>
                                      <th
                                        style={{
                                          border:
                                            "1px solid rgba(76, 101, 147, 0.7411764706)",
                                          padding: "10px",
                                          backgroundColor:
                                            "rgba(76, 101, 147, 0.7411764706)",
                                          color: "#fff",
                                        }}
                                      >
                                        {day}
                                      </th>
                                      <td
                                        style={{
                                          border:
                                            "1px solid rgba(76, 101, 147, 0.7411764706)",
                                          padding: "10px",
                                        }}
                                      >
                                        {serviceDetails?.schedule[i]?.times?.[
                                          index
                                        ]
                                          ? `${serviceDetails?.schedule[i]?.times?.[index]} ч.`
                                          : ""}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </>
                          </>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
                {/* <p>{serviceDetails.approach}</p> */}
              </div>
              <div>
                <h3>Нашият подход</h3>
                <p>{serviceDetails.approach}</p>
              </div>
              <div className="other-service">
                <h3>Свързани услуги</h3>
                <div className="row">
                  {Services.slice(0, 3).map((serves, sitem) => (
                    <div className="col-lg-4 col-md-6 col-12" key={sitem}>
                      <div className="service_card">
                        <div className="icon">
                          <i>
                            <FontAwesomeIcon
                              className="single-speciality-icon"
                              icon={getIcon(serves.icon)}
                            />
                          </i>
                        </div>
                        <div className="content">
                          <h3>{serves.title}</h3>
                          <p>{serves.description}</p>
                          <Link
                            onClick={ClickHandler}
                            to={`/specialitiy-single/${serves.slug}`}
                          >
                            <i className="flaticon-right-arrow"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="cta_form_s2">
                                <div className={"title"}>
                                    <h3>Make An Appointment</h3>
                                    <p>Get in touch with us to see how we can help you with your Problems.</p>
                                </div>
                                <ServiceFrom />

                            </div> */}
            </div>
            <div className="col-lg-4 col-12">
              <ServiceSidebar />
            </div>
          </div>
        </div>
      </section>
      <CtafromSection hclass={'ctafrom_section'} />
            <Footer hclass={'wpo-site-footer'} />
      <Scrollbar />
    </Fragment>
  );
};
export default ServiceSinglePage;
