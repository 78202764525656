import Partners from "../../api/Partners";
import Grid from "@mui/material/Grid";

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const allPartners = Partners.map((el) => {
  return (
    <Grid style={{ cursor: "pointer" }} item key={el.id} xs={3} sm={2} md={2}>
      <img
        className="partner-img"
        alt="pic"
        onClick={(e) => {
          e.preventDefault();
          openInNewTab(el.url);
        }}
        src={el.image}
        herf="www.google.com"
      ></img>
    </Grid>
  );
});

const PartnersComponent = () => {
  return (
    <div className="container">
      <div style={{paddingTop:"10px !important", paddingBottom:"60px", paddingLeft:"20px", paddingRight:"20px"}}>
        <Grid className="partners-grid" container spacing={1}>
          {" "}
          {allPartners}
        </Grid>
      </div>
    </div>
  );
};
export default PartnersComponent;
