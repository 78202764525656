import React, { Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import Doctors from "../../api/Doctors";
import ServiceSidebar from "./sidebar";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Logo from "../../images/mc-dencia-photos/BothLogos1.png";
import CtafromSection from "../../components/CtafromSection/CtafromSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFemale, faMale } from "@fortawesome/free-solid-svg-icons";

const getIcon = (name) => {
  switch (name) {
    case "faFemale":
      return faFemale;
    default:
      return faMale;
  }
};

function formatPhoneNumber(phoneNumber) {
  let digits = phoneNumber.replace(/\D/g, "");

  if (digits.startsWith("359") && digits.length === 12) {
    let formattedNumber = `+359-${digits.slice(3, 5)}-${digits.slice(
      5,
      8
    )}-${digits.slice(8, 11)}`;
    return formattedNumber;
  } else {
    return phoneNumber;
  }
}

const DoctorSinglePage = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const { id } = useParams();
  const serviceDetails = Doctors.find((item) => item.id == id);

  return (
    <Fragment>
      <Navbar Logo={Logo} hclass={"wpo-site-header wpo-site-header-s2"} />
      <PageTitle pageTitle={serviceDetails.name} pagesub={"Лични лекари"} />
      <section className="service_single section-padding">
        <div className="container">
          <div className="row g-0">
            <div className="col-lg-8 col-12 service_content">
              <div className="p-div-4">
                <h3>Основни дейности</h3>
                <ul>
                  <li>
                    {" "}
                    Специалист обща медицина{" "}
                    {serviceDetails?.otherSpec &&
                      `и ${serviceDetails?.otherSpec}`}
                  </li>
                  {serviceDetails?.workingType?.length &&
                    serviceDetails?.workingType.map((c) => (
                      <li key={`${c}`}>{c}</li>
                    ))}
                </ul>
              </div>

              {serviceDetails?.more?.length && (
                <div className="p-div-4">
                  <h3>Други дейности</h3>
                  <ul>
                    {serviceDetails?.more.map((c) => (
                      <li key={`${c}_more_activities`}>{c}</li>
                    ))}{" "}
                  </ul>
                </div>
              )}

              <div className="p-div-4">
                <h3>Контакт</h3>
                <ul>
                  <li>
                    <a className="contact" href={`tel:${serviceDetails.phone}`}>
                      {formatPhoneNumber(serviceDetails.phone)}
                    </a>
                  </li>
                </ul>
              </div>
              {serviceDetails?.hoursPerDay?.length && (
                <div className="p-div-4">
                  <h3>График</h3>
                  {
                    <>
                      <table
                        className="table_web"
                        style={{
                          width: "100%",
                          borderCollapse: "collapse",
                          marginBottom: "20px",
                          fontSize: "19px",
                          border: "1px solid rgba(76, 101, 147, 0.7411764706)",
                        }}
                      >
                        <thead>
                          <tr>
                            {[
                              "Понеделник",
                              "Вторник",
                              "Сряда",
                              "Четвъртък",
                              "Петък",
                            ].map((day, index) => (
                              <th
                                key={index}
                                style={{
                                  border:
                                    "1px solid rgba(76, 101, 147, 0.7411764706)",
                                  padding: "10px",
                                  backgroundColor:
                                    "rgba(76, 101, 147, 0.7411764706)",
                                  color: "#fff",
                                }}
                              >
                                {day}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {serviceDetails?.hoursPerDay.map((t, ind) => (
                              <td
                                key={"t" + ind}
                                style={{
                                  border:
                                    "1px solid rgba(76, 101, 147, 0.7411764706)",
                                  padding: "10px",
                                }}
                              >
                                {t ? `${t} ч.` : ""}
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                      <table
                        className="table_mobile"
                        style={{
                          width: "100%",
                          borderCollapse: "collapse",
                          marginBottom: "20px",
                          fontSize: "19px",
                          border: "1px solid rgba(76, 101, 147, 0.7411764706)",
                        }}
                      >
                        <tbody>
                          {[
                            "Понеделник",
                            "Вторник",
                            "Сряда",
                            "Четвъртък",
                            "Петък",
                          ].map((day, index) => (
                            <tr key={index}>
                              <th
                                style={{
                                  border:
                                    "1px solid rgba(76, 101, 147, 0.7411764706)",
                                  padding: "10px",
                                  backgroundColor:
                                    "rgba(76, 101, 147, 0.7411764706)",
                                  color: "#fff",
                                }}
                              >
                                {day}
                              </th>
                              <td
                                style={{
                                  border:
                                    "1px solid rgba(76, 101, 147, 0.7411764706)",
                                  padding: "10px",
                                }}
                              >
                                {serviceDetails?.hoursPerDay[index]
                                  ? `${serviceDetails?.hoursPerDay[index]} ч.`
                                  : ""}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  }
                </div>
              )}

              <div className="other-service">
                <h3>Заместващи лекари</h3>
                <div className="row">
                  {serviceDetails.helper.map((serves, sitem) => (
                    <div className="col-lg-4 col-md-6 col-12" key={sitem}>
                      <div className="service_card">
                        <div className="icon">
                          <i>
                            <FontAwesomeIcon
                              className="single-speciality-icon"
                              icon={getIcon(serves.icon)}
                            />
                          </i>
                        </div>
                        <div className="content">
                          <h3>
                            {serves.id
                              ? Doctors.find((item) => item.id == serves.id)
                                  .name
                              : serves.helperName}
                          </h3>
                          {serves.id ? (
                            <Link
                              onClick={ClickHandler}
                              to={`/doctor-single/${serves.id}`}
                            >
                              <i className="flaticon-right-arrow"></i>
                            </Link>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <ServiceSidebar />
            </div>
          </div>
        </div>
      </section>
      <CtafromSection hclass={'ctafrom_section'} />
      <Footer hclass={'wpo-site-footer'} />
      <Scrollbar />
    </Fragment>
  );
};
export default DoctorSinglePage;
