const Services = [
  {
    id: 1,
    icon: "faBaby",
    title: "Акушерство и Гинекология",
    description:
      "Ние предоставяме специализирани услуги за наблюдение на бременни, както и различни ехографски изследвания за жени.",
    moreDescription:
      "В нашето акушерско и гинекологично отделение предлагаме пълна гама от услуги за жените през различни етапи от живота им. Нашите специалисти наблюдават бременностите, като осигуряват най-добри медицински грижи, и извършват специализирани ехографски изследвания, които включват трансвагинална и абдоминална ехография, както и диагностика на щитовидната жлеза. Също така, предлагаме консултации и напътствия за здравето на жените, които преминават през различни етапи на живота.",
    hasNZOK: true,
    capabilities: [
      "Ехомамография, Трансвагинална ехография",
      "Ехография щитовидна жлеза, Абдоминална ехография",
      "Наблюдение на бременни",
    ],
    approach:
      "Нашите специалисти се грижат за всяка бъдеща майка и осигуряват специализирани услуги за здравето на бременните жени. Ние провеждаме висококачествени ехографски изследвания, които осигуряват точни и навременни резултати за здравето на пациента. Прилагаме персонализирано внимание към всеки пациент, като използваме съвременни технологии за най-добри резултати.",
    slug: "obstetrics-gynecology",
    specialists: ["Д-р Таня Джонова", "Д-р Цветомил Мавров- 4D ехография"],
    schedule: [
      {
        id: "Д-р Таня Джонова",
        times: [
          "08.00-12.00",
          "08.00-12.00",
          "08.00-12.00",
          "08.00-12.00",
          "08.00-12.00",
        ],
      },
      {
        id: "Д-р Цветомил Мавров",
        times: [
          "09.00-12.00",
          "09.00-12.00",
          "09.00-12.00",
          "09.00-12.00",
          "09.00-12.00",
        ],
      },
    ],
  },
  {
    id: 2,
    icon: "faLungsVirus",
    title: "Вътрешни болести",
    description:
      "Предлагаме диагностика и лечение на вътрешни болести, включително УЗД и ЕКГ изследвания за оценка на вътрешните органи.",
    moreDescription:
      "Нашите специалисти по вътрешни болести осигуряват диагностика и лечение на широк спектър от заболявания, засягащи вътрешните органи. Предоставяме услуги като ехографски изследвания на вътрешни органи, включително черен дроб, бъбреци и сърце, както и електрокардиограма (ЕКГ) за анализ на сърдечната активност. Работим с пациенти, страдащи от заболявания като високо кръвно налягане, диабет, респираторни проблеми и други. Нашият екип се стреми към точно диагностициране и лечение, с цел подобряване на качеството на живот на пациентите.",
    hasNZOK: true,
    capabilities: ["УЗД на вътрешни органи", "ЕКГ"],
    approach:
      "Нашите лекари разполагат с дългогодишен опит в диагностицирането и лечението на вътрешни заболявания. Използваме съвременни диагностични техники като УЗД и ЕКГ за надеждни резултати. Предоставяме индивидуализирани консултации за всяко състояние, като се стремим към минимизиране на рисковете за здравето на пациента.",
    slug: "internal-medicine",
    specialists: [
      "Д-р Милка Нешева",
      "Д-р Виделинка Цветанова",
      "Д-р Ванко Ванков",
    ],
    schedule: [
      {
        id: "Д-р Милка Нешева",
        times: ["10.00-12.00", "10.00-12.00", "", "10.00-12.00", ""],
      },
    ],
  },
  // {
  //   id: 3,
  //   icon: 'faPills',
  //   title: 'Ендокринология',
  //   description: 'Предоставяме консултации и ехографски изследвания за заболявания на щитовидната жлеза.',
  //   moreDescription: 'Ендокринологията е област, в която се фокусираме върху диагностика и лечение на заболявания на щитовидната жлеза и други ендокринни нарушения. Нашите специалисти провеждат различни ехографски изследвания на щитовидната жлеза, за да осигурят точни диагнози. Предлагаме индивидуализиран подход при лечението на пациенти със заболявания като хипотиреоидизъм, хипертиреоидизъм, автоимунни заболявания и др.',
  //   hasNZOK: false,
  //   capabilities: [],
  //   approach: "Обслужваме пациенти със заболявания на щитовидната жлеза, като прилагаме напреднали техники за диагностика и лечение. Използваме съвременни методи за проследяване на състоянието на пациента, осигурявайки персонализирани терапии. Стремим се към дългосрочни решения, които да гарантират здравето и благополучието на нашите пациенти."
  // },
  {
    id: 4,
    icon: "faXRay",
    title: "Ехографски кабинет",
    description:
      "Извършваме висококачествени ехографски изследвания на различни органи, включително щитовидна жлеза и абдоминални органи.",
    moreDescription:
      "Нашият ехографски кабинет предлага модерна и точна диагностика чрез висококачествени ехографски изследвания. Специализираме се в изследвания на вътрешни органи, като черен дроб, бъбреци, сърце и щитовидна жлеза, използвайки най-новите технологии за постигане на най-добри резултати. Нашите опитни лекари и техници работят с пациента за да осигурят точна и навременна диагноза.",
    hasNZOK: false,
    capabilities: [],
    approach:
      "Извършваме специализирани ехографски изследвания за диагностика на различни органи с висока прецизност. Ние осигуряваме най-новото оборудване за безопасни и точни резултати. Прилагаме внимателно проследяване на състоянието на пациентите с цел навременно откриване на проблеми.",
    slug: "ultrasound-cabinet",
    specialists: ["Д-р Милка Нешева"],
    schedule: [
      {
        id: "Д-р Милка Нешева",
        times: ["10.00-12.00", "10.00-12.00", "", "10.00-12.00", ""],
      },
    ],
  },
  {
    id: 5,
    icon: "faHeart",
    title: "Кардиология",
    description:
      "Специализирани в ЕКГ и ехокардиография за диагностика на сърдечно-съдови заболявания.",
    moreDescription:
      "Нашите кардиолози осигуряват комплексна диагностика и лечение на сърдечно-съдови заболявания. Предлагаме ЕКГ изследвания за анализ на сърдечната активност и ехокардиография за визуализиране на сърцето и неговите структури. Чрез тези методи можем да диагностицираме състояния като хипертония, аритмии, сърдечна недостатъчност и други сърдечно-съдови заболявания.",
    hasNZOK: true,
    capabilities: ["ЕКГ", "Ехокардиография"],
    approach:
      "Ние предлагаме пълна диагностика на сърдечно-съдовата система, използвайки най-модерни технологии. Работим с професионалисти в областта на кардиологията, за да осигурим ефективно лечение за нашите пациенти. Всеки пациент получава персонализиран план на лечение, който да отговаря на неговите специфични нужди.",
    slug: "cardiology",
    specialists: ["Д-р Мария-Магдалена Пинтова"],
    schedule: [
      {
        id: "Д-р Мария-Магдалена Пинтова",
        times: ["09.00-16.00", "", "09.00-16.00", "", ""],
      },
    ],
  },
  // {
  //   id: 6,
  //   icon: 'faPersonDotsFromLine',
  //   title: 'Кожни и венерически болести',
  //   description: 'Предлагаме лечение на кожни и венерически заболявания, включително криотерапия и други специализирани процедури.',
  //   moreDescription: 'Нашите специалисти по кожни и венерически болести предоставят лечение на различни кожни заболявания, включително акне, екзема, псориазис и венерически инфекции. В допълнение, извършваме иновативни процедури като криотерапия за лечение на кожни проблеми, които не могат да се лекуват с медикаменти.',
  //   hasNZOK: false,
  //   capabilities: [],
  //   approach: "Ние предлагаме решения за лечение на различни кожни заболявания с висококачествени терапевтични процедури. Когато е необходимо, прилагаме иновативни методи за лечение, като криотерапия, с доказана ефективност. Всеки пациент получава специализирана грижа, базирана на най-добрите медицински практики."
  // },
  {
    id: 7,
    icon: "faBrain",
    title: "Неврология",
    description:
      "Предлагаме диагностика с ЕЕГ и доплерова сонография за различни неврологични заболявания.",
    moreDescription:
      "Неврологията в нашата клиника включва диагностика и лечение на заболявания на нервната система, като инсулти, епилепсия и деменции. Извършваме ЕЕГ за запис на мозъчната активност и доплерова сонография за оценка на кръвотока в мозъчните съдове. С тези методи можем да диагностицираме състояния, като мигрена, инсулти и други сериозни неврологични заболявания.",
    hasNZOK: false,
    capabilities: ["ЕЕГ", "Доплерова сонография"],
    approach:
      "Използваме съвременни методи за диагностика на неврологични заболявания, като ЕЕГ и доплерова сонография. Нашите специалисти осигуряват пълна грижа и наблюдение за неврологични състояния, за да предложат най-доброто лечение. Предлагаме на пациентите индивидуални планове на лечение, които отговарят на техните нужди.",
    slug: "neurology",
    specialists: ["Д-р Камелия Стоянова", "Д-р Евгени Милев"],
    schedule: [
      {
        id: "Д-р Камелия Стоянова",
        times: ["", "08.00-13.00", "08.00-13.00", "", ""],
      },
      {
        id: "Д-р Евгени Милев",
        times: ["08.00-13.00", "", "", "", "08.00-13.00"],
      },
    ],
  },
  {
    id: 8,
    icon: "faBrain",
    title: "Неврохирургия",
    description:
      "Консултации и лечение на неврохирургични състояния с напреднали техники.",
    moreDescription:
      "Неврохирургията в нашата клиника обхваща лечението на заболявания и травми на нервната система, които изискват хирургическа намеса. Работим с най-модерни технологии и използваме минимално инвазивни методи, когато е възможно, за да постигнем бързо възстановяване и минимални усложнения за пациентите.",
    hasNZOK: false,
    capabilities: [],
    approach:
      "Ние използваме напреднали хирургични техники за лечение на неврологични заболявания. Възприемаме иновативни методи и се грижим за всеки пациент по време на целия лечебен процес. Нашата цел е да възстановим пълното здраве на пациентите чрез ефективни и безопасни процедури.",
    slug: "neurosurgery",
    specialists: ["Д-р Румен Филипов"],
    schedule: [],
  },
  {
    id: 9,
    icon: "faToilet",
    title: "Нефрология",
    description:
      "Специализирани услуги за лечение на заболявания на бъбреците, включително ехография на органите на отделителната система.",
    moreDescription:
      "Ние предлагаме услуги за диагностика и лечение на заболявания на бъбреците и отделителната система. Извършваме ехография на бъбреци и пикочен мехур, за да установим проблеми като камъни в бъбреците, инфекции или други заболявания, засягащи отделителната система.",
    hasNZOK: true,
    capabilities: ["Ехография на органите на отделителната система"],
    approach:
      "Нашите специалисти в нефрологията предлагат цялостна грижа за бъбречни заболявания. Използваме модерни диагностични методи за точна диагностика и лечение на нефрологични заболявания. Ние осигуряваме персонализиран подход за лечение, който включва съвременни методи и технологии.",
    slug: "nephrology",
    specialists: [
      "Д-р Михайл Горанов",
      "Д-р Иван Георгиев",
      "Д-р Снежана Йоханова",
    ],
    schedule: [
      {
        id: "Д-р Михайл Горанов",
        times: ["", "08.00-13.00", "08.00-13.00", "", "08.00-13.00"],
      },
      {
        id: "Д-р Иван Георгиев",
        times: [
          "08.00-13.00",
          "14.00-17.00",
          "",
          "08.00-13.00",
          "",
        ],
      },
      {
        id: "Д-р Снежана Йоханова",
        times: [
          "13.00-16.00",
          "08.00-13.00",
          "08.00-14.00",
          "13.00-16.00",
          "08.00-14.00",
        ],
      },
    ],
  },
  {
    id: 10,
    icon: "faDna",
    title: "Медицинска онкология",
    description:
      "Предоставяме диагностика и лечение на различни форми на рак с напреднали терапии.",
    moreDescription:
      "В нашата клиника предлагаме специализирана диагностика и лечение на всички видове рак. Работим с мултидисциплинарни екипи, които включват онколози, хирурзи и други специалисти, за да предоставим на пациентите най-доброто възможно лечение. Използваме модерни методи, като химиотерапия, лъчетерапия и имунотерапия за лечение на рак.",
    hasNZOK: true,
    capabilities: [],
    approach:
      "Ние осигуряваме най-доброто лечение за различни видове рак с помощта на модерни методи и терапии. Нашите лекари работят с пациентите в близко сътрудничество, за да създадат индивидуален план за лечение. Стремим се към максимално намаляване на риска и увеличаване на шансовете за възстановяване на всеки пациент.",
    slug: "medical-oncology",
    specialists: ["Д-р Радослав Токин"],
    schedule: [
      {
        id: "Д-р Радослв Токин",
        times: [
          "14.00-17.00",
          "14.00-17.00",
          "14.00-17.00",
          "14.00-17.00",
          "14.00-17.00",
        ],
      },
    ],
  },
  {
    id: 11,
    icon: "faCrutch",
    title: "Ортопедия и травматология",
    description:
      "Ортопедията и травматологията се фокусират върху диагностика и лечение на заболявания и травми на опорно-двигателния апарат, включително кости, стави, мускули и сухожилия.",
    moreDescription:
      "Нашите специалисти предлагат както консервативни лечения (физиотерапия, лекарства), така и оперативни интервенции при тежки травми и заболявания. Включваме и последваща грижа и рехабилитация за пълно възстановяване на пациентите.",
    hasNZOK: true,
    capabilities: [],
    approach:
      "Използваме най-новите технологии за диагностика и лечение. Всеки пациент получава индивидуален план на лечение, който включва както медицинска, така и рехабилитационна грижа.",
    slug: "orthopedics-traumatology",
    specialists: ["Д-р Гошо Славков"],
    schedule: [
      {
        id: "Д-р Гошо Славков",
        times: [
          "09.30-12.00",
          "09.30-12.00",
          "09.30-12.00",
          "09.30-12.00",
          "09.30-12.00",
        ],
      },
    ],
  },
  {
    id: 12,
    icon: "faEye",
    title: "Очни болести",
    description:
      "Очните заболявания включват диагностика и лечение на широк спектър от проблеми със зрението, от обикновени наранявания до сложни заболявания като глаукома и катаракта.",
    moreDescription:
      "При нас ще намерите съвременни диагностични методи като периметрия и кератометрия, които ни позволяват да предложим точна диагностика и лечение. Ние се грижим за вашето зрение чрез профилактика, хирургия и специализирани консултации.",
    hasNZOK: true,
    capabilities: [
      "Периметрия, Въздушна тонометрия",
      "Авторефактометър, Кератометър",
    ],
    approach:
      "Ние използваме иновации в офталмологията, за да осигурим висококачествена грижа. Нашият екип предлага както диагностика, така и лечение на всички видове очни заболявания, съобразени с нуждите на пациента.",
    slug: "ophthalmology",
    specialists: [
      "Д-р Виолета Гогова",
      "Д-р Милена Средкова - Платени прегледи",
    ],
    schedule: [
      {
        id: "Д-р Виолета Гогова",
        times: [
          "08.00-12.00",
          "08.00-12.00",
          "08.00-12.00",
          "08.00-12.00",
          "08.00-12.00",
        ],
      },
    ],
  },
  {
    id: 13,
    icon: "faHeadSideVirus",
    title: "Психиатрия, Съдебна психиатрия",
    description:
      "Психиатрията и съдебната психиатрия се занимават с диагностика и лечение на психични разстройства и зависимостти, като предлагат както терапевтични, така и съдебни услуги.",
    moreDescription:
      "Нашият екип предлага индивидуална психотерапия, групови сесии и специализирани програми за лечение на зависимости. Работим със зависими от опиати, алкохол и хазарт. Осигуряваме и съдебно психиатрично оценяване при нужда.",
    hasNZOK: true,
    capabilities: [
      "Психодрама и групова терапия",
      "Консултации на зависими за опиоиди",
      "Сертификат за ръководител на програми за лечение с агонисти и агонист/антигонисти на опиеви рецептори",
      "Специфично лечение на зависимости към амфетамини и метамфетамини",
      "Специфично лечение на алкохолна зависимост",
      "Консултации и лечение на зависими към хазарт",
      "При необходимост консултация с психолог",
    ],
    approach:
      "Ние прилагаме мултидисциплинарен подход към лечението, включително психотерапия и медицинска интервенция, за да осигурим пълно възстановяване на психичното здраве на пациентите.",
    slug: "psychiatry-forensic-psychiatry",
    specialists: ["Д-р Пламен Петров"],
    schedule: [
      {
        id: "Д-р Пламен Петров",
        times: ["", "09.00-12.00", "", "09.00-12.00", ""],
      },
    ],
  },
  {
    id: 14,
    icon: "faLungs",
    title: "Пневмология и фтизиатрия",
    description:
      "Пневмологията и фтизиатрията се занимават с диагностика и лечение на белодробни заболявания като астма, ХОББ и туберкулоза, включително специфични изследвания като спирометрия.",
    moreDescription:
      "Нашите специалисти извършват спирометрия и други функционални изследвания на белите дробове за точна диагностика на различни заболявания. Ние осигуряваме цялостно лечение на респираторни заболявания и наблюдение на дългосрочни заболявания.",
    hasNZOK: true,
    capabilities: ["Спирометрия"],
    approach:
      "Използваме висококачествени диагностични технологии и разработваме индивидуални планове за лечение на белодробни заболявания, които включват както медикаментозна терапия, така и рехабилитационни програми.",
    slug: "pulmonology-phthisiatry",
    specialists: ["Д-р Виделинка Цветанова", "Д-р Ванко Ванков"],
    schedule: [
      {
        id: "Д-р Виделинка Цветанова",
        times: [
          "13.30-17.30",
          "13.30-17.30",
          "09.00-12:00 и 13.30-17.30",
          "13.30-17.30",
          "13.30-17.30",
        ],
      },
      {
        id: "Д-р Ванко Ванков",
        times: [
          "",
          "08.00-12.00 и 16.00-18.00",
          "",
          "",
          "08.00-12.00 и 16.00-18.00",
        ],
      },
    ],
  },
  {
    id: 15,
    icon: "faHeadSideMask",
    title: "УНГ",
    description:
      "УНГ (ухо, нос, гърло) заболявания обхващат диагностика и лечение на проблеми със слуха, дишането, говора и гърлото.",
    moreDescription:
      "Предлагаме диагностика на слухови проблеми чрез аудиометрия, както и лечение на заболявания на носа и гърлото като синузит, фарингит и ангина. Ние осигуряваме и профилактика за предотвратяване на тези заболявания.",
    hasNZOK: true,
    capabilities: ["Аудиометрия"],
    approach:
      "Нашите УНГ специалисти прилагат съвременни методи за диагностика и лечение на заболявания на слуховия и дихателния тракт, като предоставят персонализирани решения на всеки пациент.",
    slug: "ent-ear-nose-throat",
    specialists: ["Д-р Димитър Йонов"],
    schedule: [
      {
        id: "Д-р Димитър Йонов",
        times: [
          "15.00-17.00",
          "15.00-17.00",
          "15.00-17.00",
          "15.00-17.00",
          "15.00-17.00",
        ],
      },
    ],
  },
  {
    id: 16,
    icon: "faToilet",
    title: "Урология",
    description:
      "Урологията се фокусира върху заболявания на уринарната система, като инфекции на пикочните пътища, бъбречни заболявания и проблеми с простатата.",
    moreDescription:
      "Нашите уролози извършват диагностика и лечение на различни заболявания, включително камъни в бъбреците, хиперплазия на простатата и инфекции на пикочните пътища. Предлагаме и профилактика и рехабилитация след оперативни интервенции.",
    hasNZOK: true,
    capabilities: [],
    approach:
      "Ние предлагаме индивидуални планове за лечение, които включват както медикаментозна терапия, така и хирургични процедури за решаване на сложни урологични проблеми.",
    slug: "urology",
    specialists: ["Д-р Даниела Василева"],
    schedule: [
      {
        id: "Д-р Даниела Василева",
        times: [
          "07.30-11.30",
          "07.30-11.30",
          "07.30-11.30",
          "07.30-11.30",
          "07.30-11.30",
        ],
      },
    ],
  },
  {
    id: 17,
    icon: "faBedPulse",
    title: "Хирургия",
    description:
      "Хирургията включва диагностика и лечение на заболявания, които изискват оперативна намеса, като травми, тумори и други сериозни състояния.",
    moreDescription:
      "Нашият екип от хирурзи е специализиран в различни области на хирургията и осигурява високо качество на операциите и следоперативната грижа. Извършваме както спешни, така и планови операции.",
    hasNZOK: true,
    capabilities: [],
    approach:
      "Прилагаме най-съвременни хирургични методи за минимизиране на рисковете и постигане на бързо възстановяване след операция.",
    slug: "surgery",
    specialists: ["Д-р Радослв Токин", "Д-р Сергей Славков"],
    schedule: [
      {
        id: "Д-р Радослв Токин",
        times: [
          "10.00-12.00",
          "10.00-12.00",
          "10.00-12.00",
          "10.00-12.00",
          "10.00-12.00",
        ],
      },
      {
        id: "Д-р Радослв Токин",
        times: [
          "14.00-17.00",
          "14.00-17.00",
          "14.00-17.00",
          "14.00-17.00",
          "14.00-17.00",
        ],
      },
    ],
  },
  {
    id: 18,
    icon: "faBedPulse",
    title: "Съдова хирургия",
    description:
      "Съдовата хирургия се занимава с диагностика и лечение на заболявания на кръвоносните съдове, включително аневризми, тромбози и други съдови проблеми.",
    moreDescription:
      "Ние предлагаме иновативни оперативни техники за лечение на съдови заболявания и осигуряваме най-високо ниво на грижа за нашите пациенти. Съдовата хирургия включва както открити, така и минимално инвазивни процедури.",
    hasNZOK: false,
    capabilities: [
      "Д-р Огнян Матков, д.м. е специалист по Обща и Съдова Хирургия с повече от 30 години опит в областта на Съдовата Хирургия и ангиология. Специализирал в Русия, Италия, Германия и Испания.",
      "Национален консултант по Съдова хирургия повече от 20 години. Автор и съавтор на повече от 80 научни разработки и публикации като една трета са във водещи международни издания.",
      "Участвал, извършвал и внедрявал иновативни оперативни техники в областта на Микрохирургията и Съдовата хирургия в Република България. Богатия и ценен опит е гаранция, че ще получите максимално качество и ефективно лечение при съдовите заболявания.",
    ],
    approach:
      "Нашият екип се фокусира върху използването на съвременни технологии и хирургични методи за осигуряване на безопасността и успешното лечение на всички съдови заболявания.",
    slug: "vascular-surgery",
    specialists: ["Д-р Огнян Матков"],
    schedule: [],
  },
];

export default Services;
