import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import erimg from "../../images/error-404.png";

const Error = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const currentDay = currentDate.getDay();

    if (
      currentHour >= 7 &&
      currentHour < 19 &&
      currentDay >= 1 &&
      currentDay <= 5
    ) {
      window.location.replace("https://rezultati.mcdenica.com:9090");
    } else {
      setReload(true);
    }
  }, []);

  return (
    <section className="error-404-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div className="content clearfix">
              {reload && (
                <div className="error-message">
                  <h3>
                    Можете да проверявате резултатите от понеделник до петък, в
                    интервала от 07:00 до 18:00 часа.
                  </h3>
                  <p></p>
                  <Link onClick={ClickHandler} to="/" className="theme-btn">
                    {" "}
                    Върнете се назад
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Error;
