import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Img1 from "../../images/testimonial/1.jpg";
import Img2 from "../../images/testimonial/2.jpg";
import Img3 from "../../images/testimonial/3.jpg";
import SectionTitle from "../SectionTitle/SectionTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const testimonials = [
  {
    id: "01",
    img: Img1,
    Des: "Много съм доволна от услугите на клиниката. Лекарите са изключително професионални и внимателни. Чувствам се сигурна и обгрижена при всяко посещение. Препоръчвам на всеки, който търси качествено здравеопазване!",
    title: "Мария Георгиева",
    sub: "Мениджър",
  },
  {
    id: "02",
    img: Img2,
    Des: "Изключителен екип от специалисти! От първия момент се почувствах уютно и в ръцете на истински професионалисти. Прегледите и лечението бяха точни и ефективни. Много благодарна за вниманието и грижите!",
    title: "Иван Петров",
    sub: "Предприемач",
  },
  {
    id: "03",
    img: Img3,
    Des: "Клиниката предоставя най-висококачествени услуги и невероятна грижа за пациентите. Всеки път, когато посещавам, съм уверена, че съм в добри ръце. Препоръчвам я на всички мои приятели и колеги.",
    title: "Елена Иванова",
    sub: "Банкер",
  },
];

const Testimonial = (props) => {
  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <section className={"" + props.tClass} style={{ background: "#ebf7ff" }}>
      <div className="container">
        <div className="row justify-content-left">
          <div className="col-12">
            <SectionTitle title="Testimonial" subtitle="Отзиви" />
          </div>
        </div>
        <div className="row testimonial_slider">
          <Slider {...settings}>
            {testimonials.map((testitem, titem) => (
              <div className="testimonial_card" key={titem}>
                <div className="icon">
                  <i className="flaticon-quote"></i>
                </div>
                <ul>
                  <li>
                    <i className="flaticon-star"></i>
                  </li>
                  <li>
                    <i className="flaticon-star"></i>
                  </li>
                  <li>
                    <i className="flaticon-star"></i>
                  </li>
                  <li>
                    <i className="flaticon-star"></i>
                  </li>
                  <li>
                    <i className="flaticon-star"></i>
                  </li>
                </ul>
                <p>{testitem.Des}</p>
                <div className="ath">
                  <div
                    className="image"
                    style={{
                      background: "#109eff",
                      borderRadius: "50px",
                      textAlign: "center",
                    }}
                  >
                    <i style={{ width: "100%" }}>
                      <FontAwesomeIcon
                        className="single-speciality-icon"
                        icon={faUser}
                        style={{ height: "50px", marginTop: "10px", color: "white" }}
                      />
                    </i>
                    {/* <img src={testitem.img} alt="" /> */}
                  </div>
                  <div className="text">
                    <h3>{testitem.title}</h3>
                    <span>{testitem.sub}</span>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
