import React, { Fragment, useState, useEffect } from "react";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Laboratory from "../../api/Laboratory";
import LaboratoryNoNzok from "../../api/LaboratoryNoNzok";
import { Link } from "react-router-dom";
const LaboratoryTableElement = (props) => {
  const el = props.el;
  const title = (
    <tr>
      <th
        style={{
          border: "1px solid rgba(76, 101, 147, 0.7411764706)",
          padding: "10px",
          background: "rgb(59 144 206)",
          color: "white",
        }}
      >
        {el.id}. {el.type}
      </th>
    </tr>
  );

  const info = el.info.map((elInfo, index) => {
    const text = elInfo.text.map((elText, index) => {
      return (
        <tr key={elText + "_" + index}>
          <td
            style={{
              border: "1px solid rgba(76, 101, 147, 0.7411764706)",
              padding: "10px",
            }}
          >
            &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; {elText}
          </td>
        </tr>
      );
    });

    return (
      <React.Fragment key={`table_text_${index}`}>
        {elInfo.subType ? (
          <tr>
            <th
              style={{
                border: "1px solid rgba(76, 101, 147, 0.7411764706)",
                padding: "10px",
              }}
            >
              {" "}
              &nbsp; &nbsp; • &nbsp; {elInfo.subType}
            </th>
          </tr>
        ) : null}
        {text}
      </React.Fragment>
    );
  });

  return (
    <React.Fragment>
      {title}
      {info}
    </React.Fragment>
  );
};

const serviceDetails = {
  description:
    "Ние предлагаме най-висококачествени лабораторни услуги с грижа и внимание към всеки пациент.",
  mission:
    "В Клинична Лаборатория се стремим да предоставим точни и надеждни резултати, които да подпомогнат лекарите и пациентите в процеса на диагностика и лечение. Нашата мисия е да бъдем надежден партньор в здравеопазването, предлагайки висококачествени лабораторни изследвания, подкрепени от модерна технология и опитен екип от специалисти.",
};

const tableSearch = (data, search, setData) => {
  const searchedItems = data.reduce((acc, el) => {
    let hasSubType = false;
    let hasText = false;

    el.info.forEach((info) => {
      if (
        info.subType.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      ) {
        hasSubType = true;
      }
      info.text.forEach((content) => {
        if (content.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
          hasText = true;
        }
      });
    });

    if (el.type.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
      acc.push(el);
    } else if (hasSubType === true) {
      const findSubType = el.info.filter((element) =>
        element.subType.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      );

      const info = [...findSubType];
      acc.push({
        id: el.id,
        type: el.type,
        info: info,
      });
    } else if (hasText) {
      const element = {
        id: el.id,
        type: el.type,
        info: [],
      };
      el.info.forEach((info) => {
        const text = info.text.filter((content) =>
          content.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        );

        if (text.length > 0) {
          element.info.push({
            subType: info.subType,
            text: text,
          });
        }
      });
      acc.push(element);
    }
    return acc;
  }, []);

  setData(searchedItems);
};

const TableComponent = ({ data, title }) => {
  return (
    <table
      style={{
        width: "100%",
        borderCollapse: "collapse",
        fontSize: "19px",
        marginBottom: "25px",
      }}
    >
      <thead>
        <tr>
          <th
            style={{
              border: "1px solid rgba(76, 101, 147, 0.7411764706)",
              padding: "10px",
              background: "#31518d",
              color: "white",
            }}
          >
            {title}
          </th>
        </tr>
      </thead>
      <tbody>
        {data.length > 0 ? (
          data
        ) : (
          <tr>
            <td>
              <b>Няма открити изследвания.</b>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
const LaboratorySection = (props) => {
  const [search, setSearch] = useState("");
  const [laboratoryData, setLaboratoryData] = useState(Laboratory);
  const [laboratoryNoNzokData, setLaobatoryNoNzokData] =
    useState(LaboratoryNoNzok);

  useEffect(() => {
    if (search.length > 0) {
      tableSearch(Laboratory, search, setLaboratoryData);
      tableSearch(LaboratoryNoNzok, search, setLaobatoryNoNzokData);
    } else {
      setLaboratoryData(Laboratory);
      setLaobatoryNoNzokData(LaboratoryNoNzok);
    }
  }, [search]);

  const nzokData = laboratoryData.map((el, index) => {
    const keyValue = "single_table_el_" + index;
    return (
      <LaboratoryTableElement el={el} key={keyValue}></LaboratoryTableElement>
    );
  });

  const noNzokData = laboratoryNoNzokData.map((el, index) => {
    const keyValue = "single_table_el_" + index;
    return (
      <LaboratoryTableElement el={el} key={keyValue}></LaboratoryTableElement>
    );
  });

  return (
    <Fragment>
      <section className="service_single section-padding">
        <div className="container">
          <div className="row g-0">
            <div className="service_content p-div-4">
              <div>
                <h3>Нашата мисия</h3>
                <p>
                  {serviceDetails?.description} {serviceDetails?.mission}
                </p>
              </div>
              <div className="service_content p-div-4">
                <h3>Телефон за контакт</h3>
                <ul>
                  <li>
                    <a href="tel:+35996300165" className="contact">
                      +359-96-300-165
                    </a>
                  </li>
                </ul>
              </div>
              <div className="p-div-4">
                <h3>Получаване на резултати</h3>
                <div className="row g-0">
                  <div className="col-lg-6" style={{ marginBottom: "20px" }}>
                    <Link
                      onClick={console.log(10)}
                      to="/results"
                      className="theme-btn"
                    >
                      {" "}
                      Проверете онлай
                    </Link>
                  </div>
                </div>
                <div className="row g-0">
                  <ul className="col-lg-6">
                    <li>На място</li>
                    <li>Viber</li>
                  </ul>
                  <ul className="col-lg-6">
                    <li>Електронна поща</li>
                    <li>НЗИС до лекаря назначил изследванията</li>
                  </ul>
                </div>
              </div>
              <div className="p-div-4">
                <h3>Услуги</h3>
                <div className="row g-0">
                  <input
                    className="searchbar"
                    type="text"
                    name="search"
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Търси изследване"
                    style={{ marginBottom: "40px" }}
                  />
                  <div className="col-lg-6">
                    <TableComponent
                      data={nzokData}
                      title={"Изследвания по договор с НЗОК"}
                    ></TableComponent>
                  </div>
                  <div className="col-lg-6">
                    <TableComponent
                      data={noNzokData}
                      title={"Платени изследвания"}
                    ></TableComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer hclass={"wpo-site-footer_s2"} /> */}
      <Scrollbar />
    </Fragment>
  );
};
export default LaboratorySection;
