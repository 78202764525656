import React from "react";
import { Link } from "react-router-dom";
import VideoModal from "../ModalVideo/VideoModal";

// image
import Himg1 from "../../images/slider/1.png";
import Hshape from "../../images/slider/shape.svg";
import homeHeroPic from "../../images/mc-dencia-photos/home-hero.jpg";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const hero = () => {
  return (
    <section className="hero_section">
      <div className="bg_shape">
        <svg viewBox="0 0 1920 1075" fill="none">
          <path
            d="M0 0H1920V1000C1920 1000 1632 619 962 917C292 1215 0 1000 0 1000V0Z"
            fill="#EBF7FF"
          />
        </svg>
      </div>
      <div className="content">
        <h2>Вашето здраве е наш приоритет</h2>
        <h4>Медицински център<br></br>"Деница ООД"
          <br></br>гр. Монтана</h4>
        <p>
          <b>
            Кабинети за специализирана медицинска помощ<br></br>Медико-диагностична
            лаборатория<br></br>Лични лекари
          </b>
        </p>
        <p style={{marginBottom:"30px"}}>
          <Link onClick={ClickHandler} className="theme-btn" to="/laboratory">
            Клинична лаборатория
          </Link>
        </p>
        <p style={{marginBottom:"30px"}}>
          {" "}
          {/* <link>dasdasdasd</link> */}
          <Link onClick={ClickHandler} className="theme-btn" to="/results">
            Проверка на лабораторни резултати
          </Link>
        </p>
        {/* <p>
          {" "}
          <Link onClick={ClickHandler} className="theme-btn" to="/about">
            Специалности
          </Link>
        </p> */}
      </div>
      <div className="image_content">
        <div className="image">
          <img src={homeHeroPic} alt="" />
          <div className="bg_shape_2">
            <img src={Hshape} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default hero;
