import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle/SectionTitle";
import Guests from "../../api/Guests";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCross } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function isDateGreaterThanTodayDate(date1) {
  const d1 = new Date(date1);
  const d2 = new Date();

  return d1 > d2;
}

function formatDate(d) {
  const date = new Date(d);
  const months = [
    "януари",
    "февруари",
    "март",
    "април",
    "май",
    "юни",
    "юли",
    "август",
    "септември",
    "октомври",
    "ноември",
    "декември",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

const GuestsSection = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const filteredGuests = [
    ...Guests.filter((i) => isDateGreaterThanTodayDate(i?.date)).sort(
      (a, b) => new Date(a?.date) - new Date(b?.date)
    ),
  ];

  return (
    <>
      {filteredGuests.length ? (
        <section className={"" + props.hclass} style={{background: "#ebf7ff", marginTop: "50px", borderRadius:"90px", marginLeft: "30px", marginRight: "30px"}}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9 col-12">
                <SectionTitle title={"Гост лекари"} subtitle={"Гост лекари"} />
              </div>
            </div>
            <div className="row">
              {filteredGuests.map((servic, sky) => (
                <div className={filteredGuests.length == 1 ? "" : "col-lg-4 col-md-6 col-12"} key={sky}>
                  <div className="service_card">
                    <div className="icon">
                      <i>
                        <FontAwesomeIcon
                          className="single-speciality-icon"
                          icon={servic.icon}
                        />
                      </i>
                    </div>
                    <div className="content">
                      <h4>{servic.title}</h4>
                      <p>{servic.spec}</p>
                      <p>
                        {formatDate(servic.date)} от {servic.hour}
                      </p>
                      <Link
                        onClick={ClickHandler}
                        to={`/team-single/${servic.slug}`}
                      >
                        <i className="flaticon-right-arrow"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}
    </>
  );
};

export default GuestsSection;
