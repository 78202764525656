import React from "react";
import CtaForm from "./CtaForm";

const CtafromSection = (props) => {
  return (
    <section className={"" + props.hclass}>
      <div className="container">
        <div className="cta_wrap">
          <div className="content">
            <h2 style={{marginBottom: "40px"}}>Локация</h2>
            <p>
              <iframe
                title="map"
                className="frame"
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=%D0%BC%D0%B5%D0%B4%D0%B8%D1%86%D0%B8%D0%BD%D1%81%D0%BA%D0%B8%20%D1%86%D0%B5%D0%BD%D1%82%D1%8A%D1%80%20%D0%B4%D0%B5%D0%BD%D0%B8%D1%86%D0%B0&t=&z=15&ie=UTF8&iwloc=&output=embed&hl=bg"
                frameBorder={0}
                scrolling="no"
                marginHeight={0}
                marginWidth={0}
                style={{width:"100%", height:"260px", filter:"grayscale(100%)"}}
              />
            </p>
          </div>
          {/* <CtaForm/> */}
        </div>
      </div>
    </section>
  );
};

export default CtafromSection;
