const Projects = [
  {
    id: "1",
    title: "Клинична Психология",
    subtitle: "Ползи",
    topics: [
      "Оценка на детското развитие и диагностика",
      "Превенция на психоемоционалното здраве",
      "Затруднена адаптация в детската градина/училище",
      "Поведенчески и емоционални проблеми",
      "Ниска себеоценка",
      "Страхове, тревожност",
      "Кошмари",
      "Раздяла и/или загуба на близък",
      "Агресивни прояви на опозиционно поведение",
      "Психологическа подкрепа в хода на израстването и изграждането на идентичност",
      "Подкрепа на деца и юноши със специални образователни потребности",
      "Употреба на ПАВ",
    ],
    slug: "clinical-psychology",
    pimg1: "pimg1",
    pSimg: "p2img1",
    specialist: "Силвия Кроснева",
    phone: "+359883302770",
    email: "krosneva.s@gmail.com",
    workTime: <>Петък: 10:00 - 17:30 ч.<br></br>Събота: 09:30 - 13:30 ч.</>,
    quote: {
      text: `"Странно е, че колкото повече приемам себе си такъв, какъвто съм,
    толкова повече имам възможност да се променям."`,
      author: "Карл Роджърс",
    },
    strategy: {
      desctiption:
        "Нашите стратегии са насочени към предоставяне на най-високо качество услуги, които подпомагат личностното развитие и психологическото благополучие. Ние се стремим към:",
      bennefits: [
        "Индивидуален подход: Вярваме, че всеки човек е уникален и заслужава персонализирани решения, които отговарят на неговите специфични нужди и цели.",
        "Непрекъснато усъвършенстване: Нашите специалисти редовно участват в обучения и семинари, за да са в крак с най-новите методи и техники в психологията.",
        "Партньорство и сътрудничество: Работим заедно с клиенти, семейства и общности, за да осигурим цялостен и ефективен подход към психичното здраве.",
        "Етика и конфиденциалност: Стриктно спазваме етичните стандарти и гарантираме пълна конфиденциалност на информацията на нашите клиенти.",
      ],
    },
    approach: {
      desctiption:
        "Нашият подход се основава на научнообосновани практики и е насочен към създаване на подкрепяща и безопасна среда за личностно развитие. Основните елементи на нашия подход включват:",
      bennefits: [
        "Емпатия и разбиране: Стремим се да създадем доверителна връзка с нашите клиенти, като показваме искрена загриженост и разбиране към тях.",
        "Активно слушане: Внимателно изслушваме проблемите и нуждите на клиентите, за да можем да предложим най-подходящите решения.",
        "Целеполагане: Заедно с клиентите определяме ясни и реалистични цели, които да ръководят терапевтичния процес.",
        "Гъвкавост и адаптивност: Прилагаме различни терапевтични методи и техники в зависимост от индивидуалните потребности на всеки клиент.",
        "Подкрепа и мотивация: Ние вдъхновяваме и подкрепяме нашите клиенти по пътя им към личностно развитие и психично благополучие.",
      ],
    },
  },
  {
    id: "2",
    title: "Медицинска козметика",
    subtitle: "Услуги",
    topics: [
      "Хиалуронови филъри (устни, нос, скули, брадичка, контур)",
      "Заличаване на бръчки с ботокс",
      "Лазер за заличаване на петна, белези, татуировки, белези от акне",
      "Скинбустери",
      "Микропигментация",
      "Микронидлинг",
      "Мезотерапия",
      "Лечение на акне",
      "Пилинг",
    ],
    slug: "medical-cosmetics",
    pimg1: "pimg1",
    pSimg: "p2img1",
    quote: {
        text: `"Красотата е истина, истината е красота — това е всичко, което знаеш на Земята и всичко, което трябва да знаеш."`,
        author: "Джон Кийтс",
      },
    specialist: "Лилия-Виктория Петкова",
    phone: " +359897533543",
    email: "",
    workTime: "",

    strategy: {
      desctiption:
        "В областта на медицинската козметика нашите стратегии са насочени към предоставяне на най-висококачествени услуги, които подобряват и поддържат здравето и красотата на кожата. Ние се стремим към:",
      bennefits: [
        "Индивидуален подход: Вярваме, че всяка кожа е уникална и заслужава персонализирани решения, съобразени с нейните специфични нужди и цели.",
        "Непрекъснато усъвършенстване: Нашите специалисти редовно участват в обучения и семинари, за да бъдат в крак с най-новите методи и технологии в медицинската козметика.",
        "Иновации и технологии: Инвестираме в най-новите технологии и иновативни решения, за да предложим най-добрите възможни резултати на нашите клиенти.",
        "Партньорство и сътрудничество: Работим съвместно с водещи експерти и компании в областта на медицинската козметика, за да осигурим цялостен и ефективен подход към грижата за кожата.",
        "Етика и конфиденциалност: Стриктно спазваме етичните стандарти и гарантираме пълна конфиденциалност на информацията на нашите клиенти."
      ],
    },
    approach: {
      desctiption:
        "Нашият подход в медицинската козметика се основава на научнообосновани практики и е насочен към създаване на подкрепяща и безопасна среда за оптимално здраве и красота на кожата. Основните елементи на нашия подход включват:",
      bennefits: [
        "Емпатия и разбиране: Стремим се да създадем доверителна връзка с нашите клиенти, като показваме искрена загриженост и разбиране към техните нужди и желания.",
        "Активно слушане: Внимателно изслушваме проблемите и нуждите на клиентите, за да можем да предложим най-подходящите решения и процедури.",
        "Целеполагане: Заедно с клиентите определяме ясни и реалистични цели, които да ръководят козметичните процедури и терапии.",
        "Гъвкавост и адаптивност: Прилагаме различни методи и техники в зависимост от индивидуалните потребности на всеки клиент, за да осигурим най-добрите резултати.",
        "Подкрепа и мотивация: Ние вдъхновяваме и подкрепяме нашите клиенти в тяхното пътуване към по-здрава и красива кожа, като им предоставяме необходимата информация и грижа."
      ],
    },
  },
];
export default Projects;
