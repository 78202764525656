import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle/SectionTitle";
import Services from "../../api/Services";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBaby,
  faLungs,
  faFileWord,
  faEye,
  faStethoscope,
  faCrutch,
  faPlus,
  faHeart,
  faBrain,
  faPersonDotsFromLine,
  faXRay,
  faLungsVirus,
  faToilet,
  faPumpMedical,
  faHeadSideMask,
  faBedPulse,
  faDna,
  faPills,
  faHeadSideVirus,
} from "@fortawesome/free-solid-svg-icons";

const getIcon = (name) => {
  switch (name) {
    case "faBedPulse":
      return faBedPulse;
    case "faDna":
      return faDna;
    case "faPills":
      return faPills;
    case "faHeadSideVirus":
      return faHeadSideVirus;
    case "faBaby":
      return faBaby;
    case "faLungs":
      return faLungs;
    case "faFileWord":
      return faFileWord;
    case "faEye":
      return faEye;
    case "faStethoscope":
      return faStethoscope;
    case "faCrutch":
      return faCrutch;
    case "faHeart":
      return faHeart;
    case "faBrain":
      return faBrain;
    case "faPersonDotsFromLine":
      return faPersonDotsFromLine;
    case "faXRay":
      return faXRay;
    case "faLungsVirus":
      return faLungsVirus;
    case "faToilet":
      return faToilet;
    case "faPumpMedical":
      return faPumpMedical;
    case "faHeadSideMask":
      return faHeadSideMask;
    default:
      return faPlus;
  }
};

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const ServiceSection = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const {
    hclass,
    sliceStart = 0,
    sliceEnd = 3,
    showSectionTitle = true,
    AllServices = true,
  } = props;

  const [search, setSearch] = useState("");
  const [data, setData] = useState(Services);

  useEffect(() => {
    if (search.length > 0) {
      const searchedItems = Services.filter(
        (el) =>
          el.title.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
          el.description
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase()) ||
          el.moreDescription
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase())
      );
      setData(searchedItems);
    } else {
      setData(Services);
    }
  }, [search]);

  return (
    <section className={hclass}>
      <div className="container">
        {props.isForHome ? null : (
          <input
            className="searchbar"
            type="text"
            name="search"
            // value={"searchTerm"}
            // onChange={}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Търси специалност"
          />
        )}
        {showSectionTitle && (
          <div className="row justify-content-center">
            <div className="col-lg-9 col-12">
              <SectionTitle
                title={"Специалности"}
                subtitle={"Специалности"}
              />
            </div>
          </div>
        )}
        <div className="row">
          {props.isForHome ? shuffleArray(data).slice(0,3).map((servic, sky) => (
            <div className="col-lg-4 col-md-6 col-12" key={sky}>
              <div className="service_card">
                <div className="icon">
                  <i>
                    <FontAwesomeIcon
                      className="single-speciality-icon"
                      icon={getIcon(servic.icon)}
                    />
                  </i>
                </div>
                <div className="content">
                  <h4>{servic.title}</h4>
                  <p>{servic.description}</p>
                  <Link
                    onClick={ClickHandler}
                    to={`/specialitiy-single/${servic.slug}`}
                  >
                    <i className="flaticon-right-arrow"></i>
                  </Link>
                </div>
              </div>
            </div>
          )) : data.map((servic, sky) => (
            <div className="col-lg-4 col-md-6 col-12" key={sky}>
              <div className="service_card">
                <div className="icon">
                  <i>
                    <FontAwesomeIcon
                      className="single-speciality-icon"
                      icon={getIcon(servic.icon)}
                    />
                  </i>
                </div>
                <div className="content">
                  <h4>{servic.title}</h4>
                  <p>{servic.description}</p>
                  <Link
                    onClick={ClickHandler}
                    to={`/specialitiy-single/${servic.slug}`}
                  >
                    <i className="flaticon-right-arrow"></i>
                  </Link>
                </div>
              </div>
            </div>
          ))}
          {true && props.isForHome && (
            <div className="col-12">
              <div className="btn">
                <Link
                  onClick={ClickHandler}
                  to={"/specialities"}
                  className="theme-btn"
                >
                  Виж всички специалности
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
