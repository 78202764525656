import { constants } from "memfs/lib/constants";
import km from "../images/guests/km.jpeg";
import om from "../images/guests/om.jpeg";
import vspasov from "../images/guests/vspasov.jpeg";
import vgeorgiev from "../images/guests/vgeorgiev.jpeg";
import {
  faCrutch,
  faHeart,
  faBrain,
  faBedPulse,
} from "@fortawesome/free-solid-svg-icons";

const Guests = [
  {
    title: "Д-р Румен Филипов",
    spec:"Неврохирург",
    sub: "05.12.2024 г. от 14:00 ч.",
    baseLocation:`УМБАЛ "Света Анна"`,
    text: ` Д-р Румен Филипов - неврохирург от УМБАЛ "Света Анна" ще извършва
        медицински прегледи на 05.12.2024 г. от 14:00 ч.`,
    contacts: <><a href="096300173" className="contact">096-300-173</a><br></br><a className="contact" href="096300174">096-300-174</a><br></br>Ha място в МЦ „Деница“</>,
    date: "12/20/2025",
    hour:"14:00 ч.",
    slug: "gust-rf",
    Sime: "https://www.lifehospitalbg.com/wp-content/uploads/2019/04/%D0%94-%D1%80-%D0%A4%D0%B8%D0%BB%D0%B8%D0%BF%D0%BE%D0%B2-%D0%BD%D0%B5%D0%B2%D1%80%D0%BE%D1%85%D0%B8%D1%80%D1%83%D1%80%D0%B3.jpg",
    icon:faBrain
  },
  {
    title: "Д-р Веселин Георгиев",
    spec:"Кардиолог",
    baseLocation:`УМБАЛ „Лозенец“`,
    sub: "29.05.2024 г. от 10:30 ч.",
    hour:"10:30 ч.",
    text: `В МЦ „Деница ООД“ ще се проведат
        кардиохирургични консултации с водещият кардиохирург Д-р Веселин
        Георгиев, началник на клиника по кардиохирургия в УМБАЛ „Лозенец“.
        Професионалните интереси на Д-р Георгиев са насочени в областта на
        коронарната и аортна хирургия, митрално-клапно съхраняващи
        операции и миниинвазивната кардиохирургия.`,
    additional:
      "Желателно е пациентите да представят медицинска документация (ако има такава) от предишни кардиологични прегледи.",
    contacts: <><a className="contact" href="tel:096300173">096-300-173</a><br></br><a className="contact" href="tel:096300174">096-300-174</a><br></br>Ha място в МЦ „Деница ООД“</>,
    date: "12/20/2025",
    slug: "guest-vg",
    Sime: vgeorgiev,
    icon:faHeart

  },
  {
    title: "Д-р Огнян Матков и Д-р Калоян Матков",
    spec:"Обща и съдова хирургия",
    baseLocation:`УМБАЛ “Света Марина”, гр. Плевен`,
    sub: "18.12.2024 г. oт 10:00 ч.",
    hour:"10:00 ч.",
    text: `Д-р Огнян Матков, д.м. е специалист по съдова хирургия с над 30 години опит. Специализирал е в Русия, 
    Италия, Германия и Испания. Автор на над 80 научни разработки, 
    включително във водещи международни издания. Участвал е в иновативни техники в микрохирургията и 
    съдовата хирургия в България, осигурявайки високо качество и ефективно лечение.
    Д-р Огнян Матков, д.м. и д-р Калоян Матков, съдови хирурзи, УМБАЛ “Света Марина”, гр. Плевен.`,
    additional: "",
    constantsontacts: <><a className="contact" href="tel:096300173">096-300-173</a><br></br><a className="contact" href="tel:096300174">096-300-174</a><br></br><a href="tel:+359886849984" className="contact">+359-886-849-984</a><br></br>Ha място в МЦ „Деница ООД“</>,
    date: "12/18/2025",
    slug: "guest-ok",
    Sime: om,
    icon:faBedPulse
  },
  {
    title: "Д-р Виктор Спасов д. м.",
    spec:"Ортопед травматолог",
    baseLocation:`УМБАЛ "Пирогов"`,
    sub: "04.10.2024г oт 14:00ч",
    hour:"14:00ч.",
    text: `д-р Виктор Спасов д. м., водещ ортопед травматолог в УМБАЛ "Пирогов". Придобива специалност 
    "Ортопедия и травматология" през 2004 г. и има сертификати за ендопротезиране на 
    големи стави  и артроскопия.
    Д-р Спасов е преминал редица AO курсове, включително за долни крайници, таз и ацетабулум, 
    както и за перипротезни фрактури в Швейцария, Австрия и Германия. Клиничната му специализация 
    включва работа в болница „James Paget“, Англия (2010 г.). Специализира в лечението на политравма, 
    фрактури на таза и ацетабулума, както и ендопротезиране на колянни и тазобедрени стави.`,
    additional: "",
    contacts: <><a className="contact" href="tel:096300173">096-300-173</a><br></br><a className="contact" href="tel:096300174">096-300-174</a><br></br>Ha място в МЦ „Деница ООД“</>,
    date: "12/05/2025",
    slug: "guest-vs",
    Sime: vspasov,
    icon:faCrutch
  },
];

export default Guests;
