const LaboratoryNoNzok = [
    {
      id: 1,
      type: "Биохимични изследвания",
      info: [
        {
          subType: "",
          text: ["Магнезий"],
        },
      ],
    },
    {
      id: 2,
      type: "Серология и Имунология",
      info: [
        {
          subType: "",
          text: [
            "Helicobacter pylori- Ag - фекал",
            "Helicobacter pylori IgG - кръв",
            "HIV",
            "Сифилис (RPR)",
          ],
        },
      ],
    },
    {
      id: 3,
      type: "Туморни маркери",
      info: [
        {
          subType: "",
          text: [
            "СА72-4/стомах;панкреас.далак/",
            "Cyfra21-1  / бял дроб /",
            "HE4  /яйчник /",
            "SCC  / матка / ",
            "Тotal HCG  / ЧХГ",
          ],
        },
      ],
    },
    {
      id: 4,
      type: "Хормони",
      info: [
        {
          subType: "",
          text: ["fT3", "TAT"],
        },
      ],
    },
    {
      id: 5,
      type: "Хламидия",
      info: [
        {
          subType: "",
          text: [
            "Хламидия трахоматис IgG",
            "Хламидия трахоматис IgM",
            "Хламидия пневм. IgG",
            "Хламидия пневм. IgM",
          ],
        },
      ],
    },
    {
      id: 6,
      type: "Други",
      info: [
        {
          subType: "",
          text: [
            "17-OH-Progesteron",
            "АСТН / АКТХ",
            "AMH-Антимюлеров хормон",
            "ANA - профил",
            "ANA - скриниг",
            "Androstendion",
            "Anti CCP",
            "Anti GAD / 65",
            "Anti Gliadin",
            "anti HbeAg",
            "anti HDV total",
            "Anti MCV",
            "Аnti-ds DNA",
            "Anti-SARS-CoV-2 lgG (количествен)",
            "Beta CrossLaps",
            "DHEAS",
            "HbeAg",
            "IgE total",
            "inhibin B / Инхибин Б /",
            "Isulin - еднократно",
            "PTH - / Паратхормон /",
            "Total IgE",
            "TSH-R / TRAC рецепт.ант./",
            "Vitamin B12",
            "Vitamin D total",
            "Валпроева к-на / Депакин",
            "Кортизол",
            "Кръвна група",
            "ОГТП захар+3 кратно Insulin",
            "СПЕРМОГРАМА",
            "Тропонин",
            "Феритин",
            "Фолиева киселина / Фолат",
          ],
        },
      ],
    },
    {
      id: 7,
      type: "Електролити",
      info: [
        {
          subType: "",
          text: ["Cl / Хлор"],
        },
      ],
    },
    {
      id: 8,
      type: "Домашно посещение - с превоз на лабораторията (ВРЕМЕННО НЕ СЕ ПРЕДЛАГА)",
      info: [
        {
          subType: "",
          text: ["Град Монтана", "Община Монтана"],
        },
      ],
    },
  ];
  
  export default LaboratoryNoNzok;
  