import React from "react";
import CountUp from "react-countup";

const FunFact = (props) => {
  return (
    <section className={"" + props.hclass} style={{ marginBottom: "90px" }}>
      <div className="container">
        <div className="row">
          <div className="col col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="item">
              <i className="flaticon-doctor"></i>
              <h3>
                <CountUp end={20} enableScrollSpy />+
              </h3>
              <p>Специалности</p>
            </div>
          </div>
          <div className="col col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="item">
              <i className="flaticon-businesswoman"></i>
              <h3>
                <CountUp end={3020} enableScrollSpy />+
              </h3>
              <p>Доволни клиенти</p>
            </div>
          </div>
          <div className="col col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="item">
              <i className="flaticon-award"></i>
              <h3>
                <CountUp end={22} enableScrollSpy />+
              </h3>
              <p>Години опит</p>
            </div>
          </div>
          <div className="col col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="item">
              <i className="flaticon-customer-care"></i>
              <h3>
                <CountUp end={2} enableScrollSpy />
              </h3>
              <p>Регистратури</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FunFact;
