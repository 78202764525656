import React from "react";
import ContactForm from "../ContactFrom/ContactForm";

const Contactpage = () => {
  return (
    <section className="wpo-contact-pg-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-lg-10 offset-lg-1">
            <div className="office-info">
              <div className="row">
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-location-1"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Адрес</h2>
                      <p>
                        Пл. Алеко Константинов<br></br>ет.2 и ет.3<br></br>3400 Монтана
                        Център<br></br>(Hад магазин ГУМ)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-email"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Напишете имейл</h2>
                      <p><a href="mailto:mc_denica@mail.bg" className="contact">mc_denica@mail.bg</a></p>
                    </div>
                  </div>
                </div>
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-phone-call"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Обадете се сега</h2>
                      <p>
                        <b>Медицински център</b>
                        <br></br>
                        <a href="tel:+35996300173" className="contact">+359-96-300-173</a>
                        <br />
                        <a href="tel:+35996300174" className="contact">+359-96-300-174</a>
                      </p>
                      <p>
                        <b>Лаборатория</b>
                        <br></br>
                        <a href="tel:+35996300165" className="contact">+359-96-300-165</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-calendar"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Работно време</h2>
                      <p>
                        {" "}
                        Понеделник - Петък
                        <br></br>
                        07:30 - 18:00 ч.
                      </p>
                      <p>
                        {" "}
                        Събота<br></br>(При предварителни записвания)
                        <br></br>09:00 - 12:00 ч.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="wpo-contact-map-section">
        <div className="wpo-contact-map">
          <iframe
            title="comtact-map"
            src="https://maps.google.com/maps?q=%D0%BC%D0%B5%D0%B4%D0%B8%D1%86%D0%B8%D0%BD%D1%81%D0%BA%D0%B8%20%D1%86%D0%B5%D0%BD%D1%82%D1%8A%D1%80%20%D0%B4%D0%B5%D0%BD%D0%B8%D1%86%D0%B0&t=&z=15&ie=UTF8&iwloc=&output=embed&hl=bg"
          ></iframe>
        </div>
      </section>
    </section>
  );
};

export default Contactpage;
