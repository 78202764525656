import React from "react";
import SectionTitle from "../SectionTitle/SectionTitle";

/* image */
import Pross1 from "../../images/work/1.jpg";
import Pross2 from "../../images/work/2.jpg";
import Pross3 from "../../images/work/3.jpg";
import Pross4 from "../../images/work/4.jpg";
import Shape from "../../images/work/shape.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHospital,
  faMedkit,
  faUserNurse,
  faStethoscope,
} from "@fortawesome/free-solid-svg-icons";

const ProcessSection = (props) => {
  return (
    <section className={"" + props.hclass}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9 col-12">
            <SectionTitle
              title={"Нашите предимства"}
              subtitle={"Нашите предимства"}
            />
          </div>
        </div>
        <div className="work_wrapper">
          <div className="row">
            <div className="col-xl-3 col-lg-6 col-md-6 col-12">
              <div className="work_card">
                <div className="image">
                  {/* <img src={Pross1} alt="" /> */}
                  <i>
                    <FontAwesomeIcon
                      className="single-speciality-icon"
                      icon={faUserNurse}
                      style={{
                        position: "relative",
                        height: "140px",
                        margin: "0 auto",
                        // marginBottom: "5px",
                      }}
                    />
                  </i>
                  <span className="number">01</span>
                </div>
                <div className="text">
                  <h3>Специалности</h3>
                  <p>
                    В лечебното заведение работят лекари-специалисти по
                    петнадесет специалности. Към него функционира клинична
                    лаборатория за извършване на медико-дигностични изследвания.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-12">
              <div className="work_card">
                <div className="image">
                  {/* <img src={Pross2} alt="" /> */}
                  <i>
                    <FontAwesomeIcon
                      className="single-speciality-icon"
                      icon={faHospital}
                      style={{
                        position: "relative",
                        height: "140px",
                        margin: "0 auto",
                        // marginBottom: "5px",
                      }}
                    />
                  </i>
                  <span className="number">02</span>
                </div>
                <div className="text">
                  <h3>Материална база</h3>
                  <p>
                    Лечебното заведение е открито през 2002г. и разполага със
                    съвременна материална база, която непрекъснато се разширява
                    и модернизира.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-12">
              <div className="work_card">
                <div className="image">
                  {/* <img src={Pross3} alt="" />
                   */}
                    <i>
                    <FontAwesomeIcon
                      className="single-speciality-icon"
                      icon={faMedkit}
                      style={{
                        position: "relative",
                        height: "140px",
                        margin: "0 auto",
                        // marginBottom: "5px",
                      }}
                    />
                  </i>
                  <span className="number">03</span>
                </div>
                <div className="text">
                  <h3>Комплексни услуги</h3>
                  <p>
                    Ние изършваме амбулаторни прегледи, високо специализирани
                    дейности, медицински манипулации, домашни прегледи,
                    профилактични прегледи, диспансерни прегледи, майчино
                    здравеопазване и др.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-12">
              <div className="work_card">
                <div className="image">
                  {/* <img src={Pross4} alt="" /> */}
                  <i>
                    <FontAwesomeIcon
                      className="single-speciality-icon"
                      icon={faStethoscope}
                      style={{
                        position: "relative",
                        height: "140px",
                        margin: "0 auto",
                        // marginBottom: "5px",
                      }}
                    />
                  </i>
                  <span className="number">04</span>
                </div>
                <div className="text">
                  <h3>Лични лекари</h3>
                  <p>
                    На територията на лечебното заведение функционират шест
                    медицински практики за първична извънболнична медицинска
                    помощ.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="shape">
            <img src={Shape} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProcessSection;
