import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "../HomePage/HomePage";
import TeamPage from "../TeamPage/TeamPage";
import TeamSinglePage from "../TeamSinglePage/TeamSinglePage";
import ProjectPage from "../ProjectPage/ProjectPage";
import ProjectSingle from "../ProjectSingle/ProjectSingle";
import ServicePages from "../ServicePage/ServicePage";
import ServicePageLkk from "../ServicePage/ServicePageLkk";

import ServiceSinglePage from "../ServiceSinglePage/ServiceSinglePage";
import ContactPage from "../ContactPage/ContactPage";
import ResultsPage from "../ResultsPage/ResultsPage";
import ErrorPage from "../ErrorPage/ErrorPage";
import DoctorsPage from "../DoctorsPage/DoctorsPage";
import DoctorSinglePage from "../DoctorSinglePage/DoctorSinglePage";
import LaboratoryPage from "../LaboratoryPage/LaboratoryPage";
// import ClinicalPsychology from "../ProjectSingle/ClinicalPsychology";

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="home" element={<Homepage />} />
          <Route path="doctors" element={<DoctorsPage />} />
          <Route path="doctor-single/:id" element={<DoctorSinglePage />} />
          <Route path="laboratory" element={<LaboratoryPage />} />
          <Route path="specialities" element={<ServicePages />} />
          <Route path="specialities-lkk" element={<ServicePageLkk />} />
          <Route path="project-single/:slug" element={<ProjectSingle />} />
          <Route path="team" element={<TeamPage />} />
          <Route path="team-single/:slug" element={<TeamSinglePage />} />
          <Route
            path="specialitiy-single/:slug"
            element={<ServiceSinglePage />}
          />
          <Route path="project" element={<ProjectPage />} />
          <Route path="project-single/:slug" element={<ProjectSingle />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="results" element={<ResultsPage />} />
          <Route path="404" element={<ErrorPage />} />
          <Route path="*" element={<Homepage />} />
          {/* <Route path="home-2" element={<HomePage2 />} />
          <Route path="home-3" element={<HomePage3 />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="team" element={<TeamPage />} />
          <Route path="team-single/:slug" element={<TeamSinglePage />} /> */}
          {/* <Route path="shop" element={<ShopPage />} />
          <Route path="shop-single/:slug" element={<ProductSinglePage />} />
          <Route path="cart" element={<CartPage />} /> */}
          {/* <Route path="checkout" element={<CheckoutPage />} /> */}
          {/* <Route path="order_received" element={<OrderRecived />} />
          <Route path="faq" element={<FaqPage />} /> */}
          {/* <Route path="clinical-psychology" element={<ClinicalPsychology />} /> */}

          {/* <Route path="project-single/clinical-psychology" element={<ProjectSingle />} /> */}
          {/* 
          <Route path="project-single/:slug" element={<ProjectSingle />} />
          <Route path="blog" element={<BlogPage />} />
          <Route path="blog-left-sidebar" element={<BlogPageLeft />} />
          <Route path="blog-fullwidth" element={<BlogPageFullwidth />} />
          <Route path="blog-single/:slug" element={<BlogDetails />} /> */}
          {/* <Route
            path="blog-single-left-sidebar/:slug"
            element={<BlogDetailsLeftSiide />}
          />
          <Route
            path="blog-single-fullwidth/:slug"
            element={<BlogDetailsFull />}
          /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
