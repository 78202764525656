import timg1 from '../images/team/1.png'
import timg2 from '../images/team/2.png'
import timg3 from '../images/team/3.png'
import timg4 from '../images/team/4.png'
import timg5 from '../images/team/5.png'
import timg6 from '../images/team/6.png'



import Stime1 from '../images/team-single/1.jpg'
import Stime2 from '../images/team-single/2.jpg'
import Stime3 from '../images/team-single/3.jpg'
import Stime4 from '../images/team-single/4.jpg'
import Stime5 from '../images/team-single/5.jpg'
import Stime6 from '../images/team-single/6.jpg'



const Teams = [
    {
        id: '1',
        title: `Органична козметика за коса "Healthy Hair Project"`,
        subtitle: '',
        slug: 'hhp',
        url:"https://healthyhairprojectt.com/",
        timg: "https://healthyhairprojectt.com/assets/images/logos/v1/logo.png",
        Sime: Stime1,
    },
    {
        id: '2',
        title: 'Аптека "Aстра"',
        subtitle: 'гр. Монтана ул. "Цар Борис 3" №18',
        slug: 'a-a',
        url:"https://aptekaastra.com/",
        timg: "https://aptekaastra.com/media/dev/Logo_Astra_.svg",
        Sime: Stime2,
    },
    {
        id: '3',
        title: 'Медицинско облекло "Аvramoff"',
        subtitle: '',
        slug: 'm-o-av',
        url:"https://medicinskoobleklo-avramoff.com/",
        timg: "http://medicinskoobleklo-avramoff.com/images/stories/00_frontpage/logo-malko.png",
        Sime: Stime3,
    },
    // {
    //     id: '4',
    //     title: 'Leslie Alexander',
    //     subtitle: 'Surgeon',
    //     slug: 'Leslie-Alexander',
    //     timg: timg4,
    //     Sime: Stime4,
    // },
    // {
    //     id: '5',
    //     title: 'Alexander Leslie ',
    //     subtitle: 'Cardiologist',
    //     slug: 'Alexander-Leslie',
    //     timg: timg5,
    //     Sime: Stime5,
    // },
    // {
    //     id: '6',
    //     title: 'Cody Fisher',
    //     subtitle: 'Surgeon',
    //     slug: 'Cody-Fisher',
    //     timg: timg6,
    //     Sime: Stime6,
    // },


]

export default Teams;



