import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/mc-dencia-photos/BothLogos1.png";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const Footer = (props) => {
  return (
    <footer className={"" + props.hclass}>
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row">
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget contact-widget">
                <div className="widget-title">
                  <h3>Локация</h3>
                </div>
                <ul>
                  <li>
                    <i className="flaticon-location-1"></i>
                    <span>
                      пл. Алеко Константинов<br></br>
                      ет.2 и ет.3<br></br>
                      3400 Монтана Център<br></br>
                      (Над магазин ГУМ)
                    </span>
                  </li>
                </ul>
                {/* <div className="logo widget-title">
                  <img src={logo} alt="blog" />
                </div> */}
                {/* <p>
                  Mattis inelit neque quis donec eleifnd amet. Amet sed et
                  cursus eu euismod. Egestas in morbi tristique.
                </p> */}
                {/* <div className="social-widget">
                  <ul>
                    <li>
                      <Link onClick={ClickHandler} to="#">
                        <i className="flaticon-facebook-app-symbol"></i>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="#">
                        <i className="flaticon-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="#">
                        <i className="flaticon-linkedin"></i>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="#">
                        <i className="flaticon-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <div className="widget-title">
                  <h3>Връзки</h3>
                </div>
                <ul>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      Начало
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/contact">
                      Контакти
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/specialities">
                      Специалности
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/laboratory">
                      Лаборатория
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/results">
                      Лабораторни резултати
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget contact-widget">
                <div className="widget-title">
                  <h3>Контакти</h3>
                </div>
                <ul>
                  {/* <li>
                    <i className="flaticon-email"></i>
                    <span>medically@gmail.com</span>
                  </li> */}
                  <li>
                    {" "}
                    <i className="flaticon-telephone"></i>
                    <span>
                      <span>Медицински център</span>
                      <br />
                      <a href="tel:+35996300173" className="contact">
                        +359-96-300-173
                      </a>
                      <br />
                      <a href="tel:+35996300174" className="contact">
                        +359-96-300-174
                      </a>
                    </span>
                  </li>
                  <li>
                    {" "}
                    <i className="flaticon-telephone"></i>
                    <span>
                      <span>Лаборатория</span>
                      <br></br>
                      <a href="tel:+35996300165" className="contact">
                        +359-96-300-165
                      </a>
                    </span>
                  </li>
                  <li>
                    <i className="flaticon-email"></i>
                    <a href="mailto:mc_denica@mail.bg" className="contact">
                      mc_denica@mail.bg
                    </a>
                    {/* <span>mc_denica@mail.bg</span> */}
                  </li>
                </ul>
              </div>
            </div>

            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget contact-widget">
                <div className="widget-title">
                  <h3>Работно време</h3>
                </div>
                <p className="footer-text">
                  <b>Понеделник - Петък</b>
                  <br></br>
                  07:30 - 18:00 ч.
                </p>
                <p className="footer-text">
                  <b>
                    Събота<br></br>(При предварителни записвания)
                  </b>
                  <br></br>09:00 - 12:00 ч.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row g-0">
                        <div className="col col-lg-6 col-12">
                            <p className="copyright"> Copyright &copy; 2024 Medically by <Link onClick={ClickHandler} to="/">wpOceans</Link>.
                                All
                                Rights Reserved.</p>
                        </div>
                        <div className="col col-lg-6 col-12">
                            <ul>
                                <li><Link onClick={ClickHandler} to="/privace">Privace & Policy</Link></li>
                                <li><Link onClick={ClickHandler} to="/terms">Terms</Link></li>
                                <li><Link onClick={ClickHandler} to="/about">About us</Link></li>
                                <li><Link onClick={ClickHandler} to="/faq">FAQ</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> */}
    </footer>
  );
};

export default Footer;
