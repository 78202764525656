import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Results from '../../components/Results/Results'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import Logo from '../../images/mc-dencia-photos/BothLogos1.png'
import CtafromSection from '../../components/CtafromSection/CtafromSection';

const ErrorPage =() => {
    return(
        <Fragment>
            <Navbar hclass={'wpo-site-header wpo-site-header-s2'} Logo={Logo} />
            <PageTitle pageTitle={"Лабораторни резултати"} pagesub={'Лабораторни резултати'}/> 
            <Results/>
            <CtafromSection hclass={'ctafrom_section'} />
            <Footer hclass={'wpo-site-footer'} />
            <Scrollbar/>
        </Fragment>
    )
};
export default ErrorPage;



